import {Time} from "@/utils/utils";
const validateTime = {
  methods:{
    validateOneYear(start,end){
      if(! (start && end)) return true;
      // 当前年份
      let year = new Date(start).getFullYear();
      // 一年后的时间
      let yearAafter = new Date(start).setFullYear(year+1)
      if(new Date(end).getTime() > new Date(yearAafter).getTime()){
        this.$message.error('最多只可以查看一年的数据');
        return false
      }else{
        return true
      }
    },

    /*
    * @params startDate 给定开始时间 往后推 diff 天
    * @params endDate 给定结束时间 往前推 diff 天
    * */
    getTimeRange(startDate,diff,endDate){
      if(startDate){
        const start = new Time(new Date(startDate)).format('yyyy-MM-dd')
        const end = new Time(new Date(new Date(startDate).getTime() + Number(diff)*24*3600*1000)).format('yyyy-MM-dd')
        return [start+' 00:00:00',end+' 23:59:59']
      }else if(endDate){
        const end =new Time(new Date(endDate)).format('yyyy-MM-dd')
        const start = new Time(new Date(new Date(endDate).getTime() - Number(diff)*24*3600*1000)).format('yyyy-MM-dd')
        return [start+' 00:00:00',end+' 23:59:59']
      }
    }
  }
}
export default validateTime