import { request } from './fetch';

// 装修流程申请相关接口
export const getCheckList = data => request('get', '/business/decoration/approval/get/list', data)   // 获取申请列表

export const getFloorShop = data => request('get', '/business/shop/floor/shop', data)

export const processUpload = data => request('POST', '/business/process/upload', data)

export const deleteFIle = data => request('POST', '/business/file/delete',data)

export const getPdfFile = (url,data) => request('GET',url,data)

export const getModel = data => request('get', '/business/bpm/get/model', data)

export const getDraft = data => request('GET','/business/decoration/approval/getUserDraft',data)  // 查询装修草稿

export const addDraft = data => request('POST','/business/decoration/approval/draft/insert',data)  // 新增装修草稿

export const updateDraft = data => request('PUT','/business/decoration/approval/draft/update',{...data,hideLoading:true})  // 更新装修草稿

export const getHisRecord = data => request('GET','/business/decoration/approval/getHistory',data)  // 获取装修历史记录

export const getDecDetail = data => request('GET','/business/decoration/approval/process/get',data)  // 获取装修的申请、发起、审批、抄送详情

export const submitApply = data => request('PUT','/business/decoration/approval/draft/submit',data)  // 提交并发起装修流程

export const reSubmitApply = data => request('PUT','/business/decoration/approval/updateAndActivate',data)  // 更新并且重新发起流程

export const exportDecration = data => request('GET','/business/decoration/approval/list/download',data,'blob') // 导出

//装修流程审核相关接口
