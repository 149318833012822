import { request } from './fetch';

// 商户管理
export const getShopInfo = config => request('get',`/business/merchant/get/${config}`) // 查询商户详情
export const getShopPersonnelList = config => request('get', '/business/merchant/list/tree', config) // 查询商户列表
export const getMerchantList = config => request('get', '/business/merchant/list/market', config) // 查获取场馆商户员工列表
export const userTransfer = (config) => request('put',`/business/merchant/transfer/user`, config) // 员工转移
export const getShopList = config => request('get', '/business/merchant/shop', config) // 所属店铺查询
export const insertOrUpdate = config => request('post', '/business/merchant/insertOrUpdate', config) // 商户添加或修改
export const permissions = config => request('post', '/business/merchant/insertModule', config) // 商户授权
export const updatePosition = config => request('post', '/business/merchant/updatePosition', config) // 商户升职或离职
// export const getAllModuleList = config => request('get', '/business/merchant/getAllModule', config) // 查询店长的权限
export const updateModule = config => request('put', '/system/user/module/replace', config) // 更新店长的权限
export const getAllModuleList = config => request('get', '/system/user/module/assistant/manager', config) // 获取店长权限树（含可被授权的）
export const getAssistantList = config => request('get', '/system/user/module/assistant', config) // 店长获取店员权限树（含可被授权的）
export const getAllMenuList = config => request('get', '/system/user/module/tree', config) // 查询运营的权限菜单树
export const deleteShop = config => request('delete',`/business/merchant/delete/${config}`) // 查询商户详情
export const shopSynchronize = () => request('put',`/business/shop/sync/shop`) // 商户同步
export const dynamicModule = (method,config) => request(method,'/system/user/module/dynamic',config) // 动态权限
export const shopDynamicModule = (config) => request('get','/system/user/module/dynamic/shop',config) // 获取商户的动态权限
export const batchDynamicWarrant = (method,config) => request(method,'/system/user/module/dynamic/batch',config,method==='delete'?'json':'') //动态表单批量授权&批量取消授权
export const batchNormalWarrant = (method,config) => request(method,'/system/user/module/batch',config,method==='delete'?'json':'') //非动态表单批量授权&批量取消授权

export const getShopListV2 = config => request('get', '/business/shop/list/v2', config) // 店铺列表V2

// 押金设置
export const getDeposit = config => request('get', `/business/deposit/get/${config}`) // 押金设置查询
export const setDeposit = config => request('post', '/business/deposit/update', config) // 押金设置编辑

// 字段表查询

export const getSysDictList = config => request('get', '/system/sysDict/list', config) // 所属店铺查询

// 优惠券返款
export const couponRefundList = config => request('get', '/business/coupon/refund/list', config) // 卡券返款列表
export const exportCouponRefundShop = config => request('get', '/business/coupon/refund/export/shop', config, 'blob') // 导出商户承担比例与手续费费率表模板
export const importCouponRefundShop = config => request('post', '/business/coupon/refund/import/shop', config) // 导入商户承担比例与手续费费率表
export const exportCouponRefundOriginal = config => request('get', '/business/coupon/refund/export/original', config, 'blob') // 导出券核销明细表模板
export const importCouponRefundOriginal = config => request('post', '/business/coupon/refund/import/original', config) // 导入券核销明细表
export const refundApprovalList = config => request('post', '/business/coupon/refund/approval/list', config) // 审批列表
export const approvalActivityNameList = config => request('get', '/business/coupon/refund/approval/activity/name', config) // 活动名称搜索
export const approvalActivityTimeList = config => request('get', '/business/coupon/refund/approval/activity/time', config) // 活动档期搜索
export const approvalCouponChannelList = config => request('get', '/business/coupon/refund/approval/coupon/channel', config) // 券渠道搜索
export const approvalCouponNameList = config => request('get', '/business/coupon/refund/approval/coupon/name', config) // 券名称搜索
export const changeCouponRefund = (config) => request('put',`/business/coupon/refund`, config) // 批量修改卡券返款
export const deleteCouponRefund = config => request('delete',`/business/coupon/refund/deleteCouponRefund`, config, 'json') // 删除卡券返款

