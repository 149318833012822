<template>
  <div class="search-item-time">
    <el-radio-group v-model="selectTimeTag" class="time-tag" size="small" @change="changeTag">
      <el-radio-button v-for="tag in options" :key="tag.value"
                       :label="tag.value">
        {{tag.label}}
      </el-radio-button>
    </el-radio-group>
    <el-date-picker
        size="medium"
        v-model="selectTimeRange"
        type="daterange"
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        value-format="yyyy-MM-dd HH:mm:ss"
        :default-time="['00:00:00','23:59:59']"
        :clearable="false"
        @change="changeTimeRange">
    </el-date-picker>
  </div>
</template>

<script>
import {Time} from "@/utils/utils";
import validateTime from "@/mixins/validateTime";

export default {
  name: "time-search",
  mixins:[validateTime],
  props:{
    param: {
      type: Object,
      default() {
        return {
          startTime:'',
          endTime:''
        };
      },
    },
    options:{
      type: Array,
      default(){
        return []
      }
    },
    defaultSelect:Number
  },
  data(){
    return {
      selectTimeRange:'',
      selectTimeTag:0,
    }
  },
  computed:{
    _value:{
      get(){
        return this.param
      },
      set(val){
        this.$emit("update:param", val);
      }
    }
  },
  methods:{
    _handleTagTime(tagVal){
      let diffDay = tagVal;
      if(tagVal==2){  // 本周
        diffDay = new Date().getDay()-1
      }else if(tagVal==3){ // 本月
        diffDay = new Date().getDate()-1
      }
      const endTime = tagVal == 1 ? new Time(new Date().getTime()-3600*1000*24*Number(diffDay)).format('yyyy-MM-dd') : new Time().format('yyyy-MM-dd');
      const startTime = new Time(new Date().getTime()-3600*1000*24*Number(diffDay)).format('yyyy-MM-dd')
      return [startTime+' 00:00:00',endTime+' 23:59:59']
    },

    changeTag(val){
      this.selectTimeRange = '';
      [this._value.startTime,this._value.endTime] = this._handleTagTime(val);
      this.$emit('search')
    },

    changeTimeRange(val){
      [this._value.startTime,this._value.endTime] = val;
      this.selectTimeTag = '';
    },

    reset(){
      this.selectTimeTag = this.defaultSelect;
      this.changeTag(this.selectTimeTag)
    },

    validate(){
      return this.validateOneYear(this.selectTimeRange[0],this.selectTimeRange[1])
    }
  },
  mounted(){
   this.reset()
  }
}
</script>

<style scoped lang="scss">
.search-item-time{
  display: flex;
  align-items: center;
  float: left;
  padding-top: 10px;
  .time-tag{
    margin: 0 5px 0 20px;
  }
}
</style>