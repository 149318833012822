// 物业审批相关的接口


// 物业报修配置
import {request} from "./fetch";
export const filterRepairItem = config => request('get','/business/repair/type/menu',config)  // 筛选报修项
export const getRepairItemList = config => request('get','/business/repair/type/list',config)  // 获取报修项
export const editRepairItem = config => request('put','/business/repair/type/insert/child',config) // 编辑报修项
export const addRepairChildItem = config => request('post','/business/repair/type/insert/child',config)  // 新增报修项
export const addRepairItem = config => request('post','/business/repair/type/insert',config) // 新增子报修项
export const disableRepairItem = config => request('put','business/repair/type/disable',config) // 禁用报修项

export const filterArea = config => request('get','/business/repair/area/menu',config)  // 筛选报修区域
export const getRepairAreaList = config => request('get','/business/repair/area/list',config)  // 获取报修区域
export const editRepairArea = config => request('put','/business/repair/area/insert/child',config) // 编辑报修区域
export const addRepairChildArea = config => request('post','/business/repair/area/insert/child',config) // 新增子报修区域
export const addRepairArea = config => request('post','/business/repair/area/insert',config) // 新增报修区域
export const disableRepairArea = config => request('put','/business/repair/area/disable',config) // 禁用报修区域

export const saveRepairParameter = config => request('put','/business/repair/config/update',config) // 保存报修配置
export const getRepairParameter = config => request('get','/business/repair/config/get',config) // 获取报修配置

// 物业报修 - 工单管理
export const getAllStatus = config => request('get','/business/repair/node/get/status',config) // 获取所有流程状态
export const getWorkOrderList = config => request('GET','/business/repair/order/send/list',config) //  获取工单列表
export const getWorkOrderDetail = config => request('get','/business/repair/order/get',config) //  获取工单详情
export const getShopByUserId = config => request('get','/sys/sysUser/shop/get',config) //  获取工单详情
export const dispatchOrder = config => request('put','/business/repair/order/send',config) //  物业派单




// 人员管理
export const getRepairUserList = config => request('get','/business/repair/user/list',config) //获取维修人员列表
export const addRepairUser = config => request('post','/business/repair/user/insert',config) //新增维修人员
export const editRepairUser = config => request('put','/business/repair/user/update',config) //修改维修人员
export const getDetailById = config => request('GET','/business/repair/user/get',config) //获取详情
export const operatorWorker = config => request('put','/business/repair/user/operate',config) //修改员工的状态
export const dispatchWorker = config => request('get','/business/repair/user/listForOrder',config) //修改员工的状态


// 数据报表
export const orderStatistic = config => request('get','/business/repair/statistics/order/list',config) //工单统计
export const exportOrderStatistic = config => request('get','/business/repair/statistics/order/list/download',config,'blob') //导出工单统计
export const personStatistic = config => request('get','/business/repair/statistics/user/list',config) //人员统计
export const exportPersonStatistic = config => request('get','/business/repair/statistics/user/list/download',config,'blob') //导出人员统计
export const repairItemStatistic = config => request('get','/business/repair/statistics/type/list',config) //维修项统计
export const exportRepairItemStatistic = config => request('get','/business/repair/statistics/type/list/download',config,'blob') //导出维修项统计


// 运营审核
export const getOrderReviewList = config => request('get','/business/repair/order/audit/list',config) // 运营审核列表
export const passPropertyOrder = config => request('put','/business/repair/order/pass',config) // 运营审核通过
export const rejectPropertyOrder = config => request('put','/business/repair/order/refuse',config) // 运营审核驳回
export const getPropertyConfirmList = config => request('get','/business/repair/order/property/list',config) // 物业确认列表
export const getOperatorConfirmList = config => request('get','/business/repair/order/operation/list',config) // 运营确认列表
export const propertyConfirm = config => request('put','/business/repair/order/property/audit',config) // 物业确认
export const operatorConfirm = config => request('put','/business/repair/order/operation/audit',config) // 运营确认