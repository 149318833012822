export default {
    install(Vue) {
        Vue.directive('throttle', {
            bind: (el, binding) => {
                let throttleTime = binding.value; // 防抖时间
                if (!throttleTime) {
                    throttleTime = 1500;
                }
                let cbFun;
                el.addEventListener('click', event => {
                    if (!cbFun) { // 第一次执行
                        cbFun = setTimeout(() => {
                            cbFun = null;
                        }, throttleTime);
                    } else {
                        event && event.stopImmediatePropagation();
                    }
                }, true);
            },
        })
    }
}