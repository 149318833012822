import { time } from "echarts"

const reconava = {
  getStatusName(val) {
    switch (val) {
      case 0: {
        return "未提审"
      }
      case 1: {
        return "已撤销"
      }
      case 2: {
        return "处理中"
      }
      case 3: {
        return "已驳回"
      }
      case 4: {
        return "已通过"
      }
      case 5: {
        return "驳回待修改"
      }
      case 6: {
        return "草稿"
      }
      case 101: {
        return "待入职员工填写"
      }
      case 102: {
        // 已提审但还没有人审批
        return "处理中"
      }
      case 7: {
        return "已终止"
      }
    }

  },
  getStatusClass(val) {
    switch (val) {
      case 0: {
        return "no-arrainged"
      }
      case 1: {
        return "repeal"
      }
      case 2: {
        return "in-processing"
      }
      case 3: {
        return "rejected"
      }
      case 4: {
        return "passed"
      }
      case 5: {
        return "to-modify"
      }
      case 6: {
        return "draft"
      }
      case 101: {
        return ""
      }
      case 102: {
        // 已提审但还没有人审批
        return "in-processing"
      }
      case 7: {
        return "termination"
      }
    }
  },
  isCreatedByCurrentUser(createdUser){
    if(createdUser){
      let user  = JSON.parse(localStorage.getItem('userInfo'))
      return createdUser.id === user.id;
    }else{
      return false
    }
  },
  isSelf_phone(phone){
    if(phone){
      let user  = JSON.parse(localStorage.getItem('userInfo'))
      return phone === user.mobile;
    }else{
      return false
    }
  },
  filterDecimalPoint(e){
    e.target.value = e.target.value.replace(/[^\d]/g, '')
  },
  parseTime(time, cFormat,isInit = false) {
    if (arguments.length === 0) {
      return null
    }
    // debugger
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
    var date
    if (typeof time === 'object') {
      date = time
    } else {
      if (new Date(time) + '' === 'Invalid Date') {
        date = new Date(time.substr(0, 10) + 'T' + time.substr(11, 8)) // 兼容safari写法
      } else {
        date = new Date(time)
      }
    }
    let nowD = date.getDay() ? date.getDay() : 7
    let d = !isInit ? 0 : nowD - 2
    date = new Date(date.getTime() - d * 24 * 60 *60 *1000)
    const formatObj = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      i: date.getMinutes(),
      s: date.getSeconds(),
      a: date.getDay()
    }
    const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
      let value = formatObj[key]
      if (key === 'a') return ['日', '一', '二', '三', '四', '五', '六'][value]
      if (result.length > 0 && value < 10) {
        value = '0' + value
      }
      return value || 0
    })
    return timeStr
  },
  debounce (fn, dely = 300) {
    let self = this, time = null;
    return (...arg) => {
      return new Promise((resolve, reject) => {
        const execute = () => {
          fn.apply(self, arg)
            .then(res => resolve(res))
            .catch(err => reject(err))
        }
        if(!time) {
          time = Date.now()
          execute()
        }else {
          if(Date.now() - time >= dely) {
            time = Date.now()
            execute()
          }else {
            reject()
          }
        }
      })
    }
  }
}



export default reconava