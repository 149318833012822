import { request } from './fetch';

/* 服务标签 */
export const deleteServiceTag = config => request('delete', '/business/goods/service/tag/delete', config, 'json') // 删除快递公司
export const updateServiceTag = config => request('put', '/business/goods/service/tag/update', config) // 修改快递公司
export const addServiceTag= config => request('post', '/business/goods/service/tag/insert', config) // 新增快递公司
export const getServiceTagList = config => request('get', '/business/goods/service/tag/list', config) // 获取快递公司列表

/* 操作原因 */
export const deleteHandleReason = config => request('delete', '/business/goods/handle/reason/delete', config, 'json') // 删除商品类型
export const updateHandleReason = config => request('put', '/business/goods/handle/reason/update', config) // 修改商品类型
export const addHandleReason= config => request('post', '/business/goods/handle/reason/insert', config) // 新增商品类型
export const getHandleReasonList = config => request('get', '/business/goods/handle/reason/list', config) // 获取商品类型列表
/* 素材管理 */
export const directoryList = config => request('get', '/business/material/list/directory', config) // 目录列表
export const deleteDirectory = config => request('delete', '/business/material/delete/directory', config, 'json') // 删除目录
export const updateDirectory = config => request('put', '/business/material/update/directory', config) // 修改目录
export const addDirectory = config => request('post', '/business/material/insert/directory', config) // 新增目录
export const getMaterialByDirectory = config => request('get', '/business/material/get', config) // 新增目录
export const addMaterial = config => request('post', '/business/material/insert', config) // 新增素材
export const deleteMaterials = config => request('delete', '/business/material/delete/batch', config) // 批量删除素材

/* 商品类型 */
export const deleteGoodsType = config => request('delete', '/business/goods/type/delete', config, 'json') // 删除商品类型
export const updateGoodsType = config => request('put', '/business/goods/type/update', config) // 修改商品类型
export const addGoodsType = config => request('post', '/business/goods/type/insert', config) // 新增商品类型
export const getGoodsTypeList = config => request('get', '/business/goods/type/list', config) // 获取商品类型列表

/* 快递公司 */
export const deleteLogisticsCompany = config => request('delete', '/business/logistics/company/delete', config, 'json') // 删除快递公司
export const updateLogisticsCompany = config => request('put', '/business/logistics/company/update', config) // 修改快递公司
export const addLogisticsCompany= config => request('post', '/business/logistics/company/insert', config) // 新增快递公司
export const getLogisticsCompanyList = config => request('get', '/business/logistics/company/list', config) // 获取快递公司列表

/* 快递模板 */
export const deleteLogisticsTemplate = config => request('delete', '/business/logistics/template/delete', config, 'json') // 删除快递模板
export const updateLogisticsTemplate = config => request('put', '/business/logistics/template/update', config) // 修改快递模板
export const addLogisticsTemplate= config => request('post', '/business/logistics/template/insert', config) // 新增快递模板
export const getLogisticsTemplateList = config => request('get', '/business/logistics/template/list', config) // 获取快递模板列表
export const getLogisticsTemplateDetail = config => request('get', '/business/logistics/template', config) // 获取快递模板详情

/* 快递计费方式 */
export const deleteLogisticsBilling = config => request('delete', '/business/logistics/billing/delete', config, 'json') // 删除快递计费
export const updateLogisticsBilling = config => request('put', '/business/logistics/billing/update', config) // 修改快递计费
export const batchAddLogisticsBilling= config => request('post', '/business/logistics/billing/insert/batch', config) // 新增快递计费
export const getLogisticsBillingList = config => request('get', '/business/logistics/billing/list', config) // 获取计费方式列表

/* 商品管理 */
export const businessGoodsList = config => request('get', '/business/goods/list', config) // 商品列表
export const businessGoodsDetail = config => request('get', '/business/goods/get', config) // 商品详情
export const goodsInsertStepOne = config => request('post', '/business/goods/insert/step/one', config) // 新建商品-新增商品基础信息
export const goodsUpdateStepOne = config => request('put', '/business/goods/update/step/one', config) // 新建商品-修改商品基础信息
export const goodsSubmitStepOne = config => request('put', '/business/goods/submit/step/one', config) // 新建商品-提交商品基础信息
export const goodsUpdateStepTwo = config => request('put', '/business/goods/update/step/two', config) // 新建商品-修改商品库存信息
export const goodsSubmitStepTwo = config => request('put', '/business/goods/submit/step/two', config) // 新建商品-保存商品库存信息
export const goodsUpdateStepThree = config => request('put', '/business/goods/update/step/three', config) // 新建商品-修改商品详情信息
export const goodsSubmitStepThree = config => request('put', '/business/goods/submit/step/three', config) // 新建商品-保存商品详情信息并提审
export const goodsUpdateInventoryCount = config => request('put', '/business/goods/update/inventory/count', config) // 修改商品sku库存
export const goodsDelete = config => request('delete', '/business/goods/delete', config, 'json') // 批量删除
export const goodsPass = config => request('put', '/business/goods/pass', config) // 批量通过
export const goodsRefuse = config => request('put', '/business/goods/refuse', config) // 批量驳回
export const goodsOffline = config => request('put', '/business/goods/offline', config) // 批量下架
// export const businessGoodsList = config => request('get', '/business/goods/list', config) // 商品列表
// export const businessGoodsList = config => request('get', '/business/goods/list', config) // 商品列表
// export const businessGoodsList = config => request('get', '/business/goods/list', config) // 商品列表

/* 修改商品库存 */
export const updateGoodsInventory = config => request('put', '/business/goods/update/inventory/count', config) // 修改商品库存
/* 订单参数 */
export const updateOrderConfig = config => request('put', '/business/goods/order/config/update', config) // 修改商品库存
export const getOrderConfig = config => request('get', '/business/goods/order/config/get', config) // 修改商品库存

/* 商品标签 */
export const deleteGoodsTag = config => request('delete', '/business/goods/tag/delete', config, 'json') // 删除商品标签
export const updateGoodsTag = config => request('put', '/business/goods/tag/update', config) // 修改商品标签
export const addGoodsTag= config => request('post', '/business/goods/tag/insert', config) // 新增商品标签
export const getGoodsTagList = config => request('get', '/business/goods/tag/list', config) // 获取商品标签列表

/* 货品列表 */
export const getGoodsGoodsList = config => request('get', '/business/goods/goods/list', config) // 获取商品标签列表