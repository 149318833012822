<template>
  <div class="tour-dashboard-wrapper flex">
    <div class="searchBox">
      <time-search ref="timeSearch" :param.sync="filter" :options="$map.getter('tourTimeTags')"  :default-select="0" @search="searchList"></time-search>
      <div class="search-item button-group pdl16">
        <el-button type="primary" class="btn-search" @click="searchList()" size="medium">查询</el-button>
        <el-button  class="btn-reset" @click="reset()" size="medium">重置</el-button>
      </div>
    </div>

    <div class="classification">
      <div class="classification-item" v-for="(block,index) in classifications" :key="index" :style="{background:classificationBg[index]}">
        <p>{{block.name}}</p>
        <p class="classification-item_total">{{block.total}}</p>
      </div>
    </div>

    <div class="chart-content">
      <div class="statistic-block">
        <div class="chart-title">部门统计</div>
        <!--   部门统计   -->
        <div class="chart-block"  v-if="departmentSta.length">
          <div class="chart-wrapper">
            <div class="bar-chart" ref="departmentBarChartDom"></div>
          </div>
          <div class="chart-wrapper">
            <div class="pie-chart" ref="departmentPieChartDom"></div>
          </div>
        </div>
        <div v-else class="textCenter">暂无数据</div>
      </div>

      <div class="statistic-block">
        <div class="chart-title">类型统计</div>
        <!--   报修类型   -->
        <div class="chart-block" v-if="typeSta.length">
          <div class="chart-wrapper">
            <div class="bar-chart" ref="typeBarChartDom"></div>
          </div>
         <div class="chart-wrapper">
           <div class="pie-chart" ref="typePieChartDom"></div>
         </div>
        </div>
        <div v-else class="textCenter">暂无数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import TimeSearch from "@/pages/statement/approval-report/components/time-search";
import DSearch from "@/components/d-search";
import {onDownload} from "@/utils/utils";
import echarts from "@/mixins/echarts"

export default {
  name: "tourDashboard",
  components: {DSearch, TimeSearch},
  mixins: [echarts],
  data(){
    return {
      classificationBg:[
        'linear-gradient(to right bottom,#5b69fe,#979cff)',
        'linear-gradient(to right bottom,#2eb2ff,#91e0fa)',
        'linear-gradient(to right bottom,#e56f79,#f0a99d)',
        'linear-gradient(to right bottom,#ef8d48,#fcd3a6)',
        'linear-gradient(to right bottom,#62d28a,#bfe794)'
      ],
      classifications :[],
      filter:{
        startTime:'',
        endTime:''
      },
      departmentBarChart:null,
      departmentSta:[],
      departmentPieChart:null,
      typeBarChart:null,
      typePieChart:null,
      typeSta:[]
    }
  },
  mounted(){
    this.searchList()
  },
  methods:{
    searchList(){
      if(!this.$refs.timeSearch.validate()) return ;
      this.init();
    },

    init(){
      const param = {...this.filter}
    //  获取工单统计
      this.getOrderData(param)
    //  获取部门统计
      this.getDepartment(param)
    //  获取类型统计
      this.getType(param)
    },

    getOrderData(param){
      this.$api.tour.dashboardOrder(param).then(({data,code})=>{
        this.classifications = [
          {name:'新增',total:data.newCount},
          {name:'处理中',total:data.handleCount},
          {name:'超时',total:data.timeoutCount},
          {name:'移交',total:data.handoverCount},
          {name:'完成',total:data.finishCount},
        ]
      })
    },

    getDepartment(param){
      this.$api.tour.dashboardDepartment(param).then(({data,code})=>{
        this.departmentSta = (data.deptStatistic||[]).map(e=>({name:e.sysDept.deptName,...e}))
        this.departmentSta.length && (this.$nextTick(()=>{
          this.initDepartmentBarChart();
          this.initDepartmentPieChart();
        }))
      })
    },

    getType(param){
      this.$api.tour.dashboardType(param).then(({data,code})=>{
        this.typeSta = (data.typeStatistic||[]).map(e=>({name:e.tourType.typeName,...e}))
        this.typeSta.length && (this.$nextTick(()=>{
          this.initTypeBarChart();
          this.initTypePieChart();
        }))
      })
    },

    reset(){
      this.$refs.timeSearch.reset();
    },

    getBarOption(title,type,data){
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          bottom: '5%',
          left: 'center'
        },
        grid: {
          top: '15%',
          left: '3%',
          right: '4%',
          bottom: '12%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: data.map(e=>e.name),
            axisLabel:{
              overflow:'breakAll',
              interval:0,
              width:50
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '新增',
            type: 'bar',
            stack: type,
            emphasis: {
              focus: 'series'  // 高亮时聚焦当前高亮的数据所在的系列的所有图形
            },
            barWidth: "50%", // 这里不让柱形图自适应了，因为部门多的时候，避免出现柱形图拥挤的情况，这里统一固定宽度
            itemStyle:{
              color:'#7A77F2'
            },
            data: data.map(e=>e.newCount)
          },
          {
            name: '完成',
            type: 'bar',
            stack: type,
            emphasis: {
              focus: 'series'  // 高亮时聚焦当前高亮的数据所在的系列的所有图形
            },
            itemStyle:{
              color:'#F7C739'
            },
            barWidth: "50%",
            data: data.map(e=>e.finishCount)
          },
        ],
        dataZoom:[{
          type:'inside',
          start: 0,
          end: data.length<8?100:7/data.length*100,
          zoomLock:true
        }]
      }
    },

    getPieOption(title,data){
      return {
        title:{
          text:title,
          top:'0%',
          left:'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          type:'scroll',
          bottom: '5%',
          left: 'center'
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: data.map(e=>({value:e.finishCount,name:e.name}))
          }
        ]
      }
    },

    initTypePieChart(){
      this.typePieChart = echarts.init(this.$refs.typePieChartDom);
      this.typePieChart.setOption(this.getPieOption(
          '完成数量占比',
               this.typeSta
      ))
    },

    initTypeBarChart(){
      this.typeBarChart = echarts.init(this.$refs.typeBarChartDom);
      this.typeBarChart.setOption(this.getBarOption(
          '报修类型统计',
          'typeName',
          this.typeSta
        ))
    },

    initDepartmentBarChart(){
      this.departmentBarChart = echarts.init(this.$refs.departmentBarChartDom);
      this.departmentBarChart.setOption(this.getBarOption(
          '部门统计',
          'department',
            this.departmentSta
          ))
    },

    initDepartmentPieChart(){
      this.departmentPieChart = echarts.init(this.$refs.departmentPieChartDom);
      this.departmentPieChart.setOption(this.getPieOption(
          '完成数量占比',
          this.departmentSta
      ))
    }
  }
}
</script>

<style scoped lang="scss">
.tour-dashboard-wrapper{
  .classification{
    background: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    padding: 0 10px;
    .classification-item{
      display: inline-flex;
      width: 100%;
      margin: 10px;
      padding: 20px 10px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      color: #fff;
      border-radius: 10px;
      .classification-item_total{
        padding-top: 10px;
        font-size: 30px;
      }
    }
  }
  .chart-content{
    margin-top: 10px;
    background: #fff;
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    .statistic-block{
      min-height: 100px;
      .chart-title{
        font-size: 16px;
        position: relative;
        margin-left: 14px;
        padding: 5px 0;
        font-weight: bold;
        &:before{
          content:'';
          width:3px;
          height: 60%;
          top: 20%;
          position: absolute;
          background: $subjectColor;
          left: -5px;
        }
      }
      .chart-block{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-bottom: 12px;
        flex: 1;
        border-bottom: 1px dashed #e7e7e7;
        .chart-wrapper:first-child {
          width: 60%;
          height: 400px;
        }
        .chart-wrapper{
          flex: 1;
          .bar-chart{
            height: 100%;
          }
          .pie-chart{
            width: 100%;
            height: 100%;
          }
        }
      }
    }

  }
}
</style>