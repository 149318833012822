import { request } from './fetch';

// 楼层列表
export const getfloorList = config => request('get', '/sys/floor/list', config) // 楼层列表


// 状态信息
export const getStatusList = config => request('get', '/system/sysDict/list', config) // 状态信息

// 登录
export const setLogin = config => request('post', '/sys/sysUser/login', config) // 登录
export const setLoginV2 = config => request('post', 'sys/sysUser/login/v2', config) // 带图形验证码登录
export const getPictureCode = () => request('get', '/sys/sysUser/picture/code') // 获取图形验证码
export const getPublicKey = () => request('get', '/sys/sysUser/public/key') // 获取图形验证码

export const getAuth = config => request('POST','/sys/sysUser/loginFromPortal',config)

export const marketPeopleTree = () => request('GET','/sys/sysDept/tree/user/market') // 当前场馆下的人员树

// 系统日志
export const getSysLogList = config => request('get', '/business/sys/log/list', config) // 日志列表
export const exportSysLog = config => request('get', '/business/sys/log/export', config, 'blob') // 下载内容(excel)
export const deleteSysLog = param => request('delete','/business/sys/log',param,'json') // 删除日志


// 部门管理
export const getSysDeptTree = config => request('get', 'sys/sysDept/tree', config) // 部门左侧树查询
export const getSysDeptInfo = config => request('get', `sys/sysDept/get/${config}`) // 查询部门详情
export const insertOrUpdate = config => request('post', 'sys/sysDept/insertOrUpdate', config) // 部门添加或者更新
export const deleteDept = config => request('DELETE', `sys/sysDept/delete/${config}`) // 部门删除
export const getSysDeptList = config => request('get', 'sys/sysDept/list', config) // 查询部门列表


// 职位管理
export const getSysPositionList = config => request('get', 'sys/sysPosition/list', config) // 查询职位列表
export const positionUpdate = config => request('post', 'sys/sysPosition/insertOrUpdate', config) // 职位添加或者更新
export const deletePosition = config => request('DELETE', `sys/sysPosition/delete/${config}`) // 职位删除

// 角色管理
export const getSysRoleList = config => request('get', 'sys/sysRole/list', config) // 查询角色列表
export const getSysModuleTree = config => request('get', 'sys/sysModule/tree', config) // 角色权限查询
export const sysRoleUpdate = config => request('post', 'sys/sysRole/insertOrUpdate', config) // 角色添加或者更新
export const getRoleInfo = config => request('get', `sys/sysRole/get/${config}`) // 角色详情
export const deleteSysRole = config => request('DELETE', `sys/sysRole/delete/${config}`) // 角色删除

// 用户管理
export const getSysUserTree = config => request('get', 'sys/sysUser/list', config) // 用户列表
export const uploadFile = (config, biz) => request('post', `/business/upload?biz=${biz}`, config) // OSS上传
export const deleteFile = (config) => request('post', `oss/delete?url=${config}`) // OSS删除
export const sysUserUpdate = config => request('post', 'sys/sysUser/insertOrUpdate', config) // 用户添加或者更新
export const getUserInfo = config => request('get', `sys/sysUser/get/${config}`) // 用户详情
export const getBusinessList = config => request('get', 'sys/business/list', config) // 业态列表
export const getFloorList = config => request('get', 'sys/floor/list', config) // 楼层列表
export const sysUserAuthorization = config => request('post', 'sys/sysUser/authorization', config) // 用户授权
export const deleteSysUser = config => request('DELETE', `sys/sysUser/delete/${config}`) // 用户删除（离职）
export const changePassword = config => request('post', `/sys/sysUser/change/password`, config) // 修改密码
export const sychronizeUser = ()  => request('put','/sys/sysUser/sync/dept/user')  // 同步用户和部门

// 商户管理
export const importExcel = (config) => request('post', `/business/merchant/import/excel`, config) // 批量导入店长
export const importUserExcel = (config) => request('post', `/business/merchant/import/user/excel`, config) // 批量导入店员
export const getExportExcel = (config) => request('get', '/business/merchant/export/excel', config, 'blob') // 导出批量导入店长excel模板
export const merchantTransfer = (config) => request('put', `/business/merchant/transfer`, config) // 商户店铺转移
export const exportShopUser = (config) => request('get', '/business/merchant/export/shop/user', config, 'blob') // 导出商户

// 安保管理
export const securityList = param => request('get','/business/guard/user/list',param) // 安保列表
export const securityAdd = param => request('post','/business/guard/user/insert',param) // 新增
export const securityEdit = param => request('put','/business/guard/user/update',param) // 编辑
export const securityDel = param => request('delete','/business/guard/user/delete',param,'json') // 删除


// 日志管理
export const getScanLogList = config => request('get', '/business/scanLog/list', config) // 扫码日志列表

// 人事管理
export const sysJobGradeDelete = config => request('delete', `/business/sys/job/grade/delete/${config}`) // 职级删除
export const sysJobGradeGet = config => request('get', `/business/sys/job/grade/get/${config}`) // 职级详情
export const sysJobGradeInsert = config => request('post', `/business/sys/job/grade/insert`, config) // 职级添加
export const sysJobGradeList = config => request('get', `/business/sys/job/grade/list`, config) // 职级列表
export const sysJobGradeUpdate = config => request('put', `/business/sys/job/grade/update`, config) // 职级修改

// 岗位
export const sysPostDelete = config => request('delete', `/business/sysPost/delete/${config}`) // 岗位删除
export const sysPostGet = config => request('get', `/business/sysPost/get/${config}`) // 岗位详情
export const sysPostInsert = config => request('post', `/business/sysPost/insert`, config) // 岗位添加
export const sysPostList = config => request('get', `/business/sysPost/list`, config) // 岗位列表
export const sysPostUpdate = config => request('put', `/business/sysPost/update`, config) // 岗位修改

//参数设置
export const getConfig = () => request('get',`sys/sysModule/real/authentication/enable/get`) // 获取实名认证的开关参数
export const getMarketConfigList = () => request('get',`/business/market/config/list`) // 获取场馆配置列表
export const getMarketConfigType = (param) => request('get',`/business/market/config/type`, param) // 获取实名认证的开关参数
export const updateMarketConfigList = (param) => request('put',`business/market/config/update`, param) // 获取实名认证的开关参数
export const changVerified = param => request('put',`sys/sysModule/real/authentication/enable`,param) // 改变实名认证的开关
export const appVersion = param => request('get',`/business/app/version/get`,param) // app版本
export const appUpdate = param => request('post',`/business/app/version/insert`,param) // 强制更新版本
export const changeOpenDeposit = param => request('put', 'business/cashier/config', param) // 修改收银员押金的开关
export const getOpenDeposit = param => request('get', 'business/cashier/config', param) // 获取收银员开关参数

// 菜单管理
export const getVenueList = param => request('POST','/business/market/list',param) // 获取场馆列表
export const getRoleByMarketId = param => request('get','/sys/module/market/tree',param) // 获取场馆下的菜单
export const updateMenuByMarketId = param => request('put','/sys/module/market/update/batch',param) // 修改场馆下的菜单
export const updateMenu = param => request('put','/sys/sysModule/update/batch',param) // 修改系统菜单(超级管理员)
export const addMenu = param => request('POST','/sys/sysModule',param) // 添加菜单(超级管理员)
export const getIconList = param => request('get','/sys/module/icon/list',param) // 查询系统默认图标
export const syncSysModule = () => request('get','/sys/sysModule/sync') // 同步菜单权限

// bi日志
export const getBiLogList = param => request('get','/business/bi/handle/log/list',param) // 查询Bi日志

//多场馆
export const listEnable = () => request('get','/business/user/market/list/enable?pageSize=-1&pageNum=1') // 获取集团下用户有效场馆列表
export const getMarketUser = param => request('get','/business/user/market/user',param) // 获取指定用户的有效场馆列表
export const changeMarket = param => request('put','/sys/sysUser/change/market',param) // 切换用户场馆 参数 marketId
export const tokenInfo = () => request('get','/sys/sysUser/token/info') // 获取用户权限信息

// 上报销售配置
export const setSaleConfig = param => request('put','/business/report/sale/config',param) // 
export const getSaleConfig = param => request('get','/business/report/sale/config',param) // 
export const updateSaleConfig = param => request('put','/business/report/sale/config/batch',param) // 

// 关联表单
export const approvalDynamicList = param => request('get','/business/business/type/list/approval/dynamic',param) // 获取动态表单相关业务列表
export const aclSelectList = param => request('get','/business/approve/external/acl/select/list',param) // 用户权限流程下拉框接口
export const businessList = param => request('get','/business/approval/business/list',param) // 业务关联列表
export const businessInsert = config => request('post', `/business/approval/business/insert`, config) // 新增业务关联
export const cancelRelation = config => request('delete', `/business/approval/business`, config,'json') // 取消关联
export const getBusinessListByCode = param => request('get',`/business/approval/business/list/code`, param) // 根据流程code获取业务

