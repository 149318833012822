import { request } from './fetch';

// 组件数据
// 品牌下拉框
export const componentBrand =
  config =>
    request('get', '/business/approve/component/brand');
// 业态下拉框
export const componentBusiness =
  config =>
    request('get', '/business/approve/component/business');
// 店铺下拉框
export const componentShop =
  config =>
    request('get', '/business/approve/component/shop');
// 部门树
export const componentDept =
  config =>
    request('get', '/business/approve/component/dept');
// 职务树
export const componentPost =
  config =>
    request('get', '/business/approve/component/post');
// 人员树
export const componentUser =
  config =>
    request('get', '/business/approve/component/user');
// 中国省市区街道数据
export const componentRegion =
  code =>
    request('get', `/business/approve/component/region?code=${code}`,{hideLoading:true});

// 返款账户信息
export const componentShopAccount =
  config =>
    request('get', `/business/approve/component/shop/account/${config.marketCode}`, {
      shopCode: config.shopCode
    });

// 电子现金返款查询（支持多月份）
export const cardRefundInfo =
  config =>
    request('get', `/business/approve/component/card/refund/months/${config.marketCode}`, {
      cardType: config.cardType,
      month: config.month,
      shopCode: config.shopCode
    });

// EMA活动分类查询
export const componentEmaClassify =
  config =>
    request('get', `/business/approve/component/ema/classify/${config.marketCode}`);

// EMA活动返款(根据档期)
export const emaRefundData =
  config =>
    request('get', `/business/approve/component/ema/refund/data/${config.marketCode}`, {
      activityIds: config.activityIds,
      classifyId: config.classifyId,
      schedule: config.schedule,
      shopCode: config.shopCode
    });

// EMA活动档期查询
export const componentEmaSchedule =
  config =>
    request('get', `/business/approve/component/ema/schedule/${config.marketCode}`, {
      classifyId: config.classifyId,
      shopCode: config.shopCode
    });

// EMA活动查询(根据档期)
export const componentEmaSelect =
  config =>
    request('get', `/business/approve/component/ema/select/${config.marketCode}`, {
      classifyId: config.classifyId,
      schedule: config.schedule,
      shopCode: config.shopCode,
      approveDataId: config.approveDataId
    });
// 电子现金不可选的月份
export const shopCarddisable =
  config =>
    request('get', `/business/approve/component/shop/card/disable/${config.marketCode}`, {
      cardType: config.cardType,
      shopCode: config.shopCode,
      approveDataId: config.approveDataId
    });

// 活动名称
export const componentActivity =
  config =>
    request('get', `/business/approve/component/ema/activities/${config.marketCode}?shopCode=${config.shopCode}`);
// 活动还款金额查询
export const componentActivityRefund =
  config =>
    request('get', `/business/approve/component/ema/refund/${config.marketCode}?activities=${config.activities}&shopCode=${config.shopCode}`);

// 卡类型
export const componentCardType =
  config =>
    request('get', `/business/approve/component/card/type/${config.marketCode}`);
// 电子现金还款
export const componentCardTypeRefund =
  config =>
    request('get', `/business/approve/component/card/refund/${config.marketCode}?shopCode=${config.shopCode}&month=${config.month}&cardType=${config.cardType}`);

// 其他接口
// 用户权限菜单
export const externalAcl =
  config =>
    request('get', '/business/approve/external/acl');
// // 预览接口-表单详情
// export const externalForm =
//   basicId =>
//     request('get', `/business/approve/external/form/${basicId}`);

/**
 * 获取最新详情数据
 * @param {string} basicId
 * @returns
 */
export const externalForm =
  (basicId) =>
    request('get', `/business/approve/external/latest/${basicId}`);

// 根据菜单-表单code返回列表数据
export const externalList =
  (code, data) =>
    request('post', `/business/approve/external/list/${code}`, data);

// 根据菜单-表单code导出excel
export const exportList =
    (code,data) =>
        request('post',`/business/approve/external/list/export/${code}`,data)
// 流程key查询接口
export const externalKey =
  () =>
    request('get', `/business/approve/external/key`);

// 审核管理-动态表单
/**
 * 获取该用户的草稿
 * @param {number} basicId 
 * @returns 
 */
export const getUserDraft =
  (basicId) =>
    request('get', `/business/approval/dynamic/getUserDraft?basicId=${basicId}`);

/**
 * 新增草稿
 * @param {{ basicId:number, content:object }} data 
 * @returns 
 */
export const insert =
  (data) =>
    request('post', `/business/approval/dynamic/insert`, data);

/**
 * 更新草稿
 * @param {{ content:object, id:number }} data 
 * @returns 
 */
export const update =
  (data) =>
    request('put', `/business/approval/dynamic/update`, {...data,hideLoading:true});

/**
 * 提交并发起流程
 * @param {{activityKey:string, activityNodeDetails:object[], content:{}, id:number }} data
 * @returns
 */
export const submit =
  (data) =>
    request('put', `/business/approval/dynamic/submit`, data);

/**
 * 获取申请、发起、审批、抄送详情
 * @param {string} id
 * @returns
 */
export const getDetail =
  (id) =>
    request('get', `/business/approval/dynamic/getDetail?id=${id}`);

/**
 * 获取待办详情
 * @param {string} taskId
 * @returns
 */
export const getWaitDetail =
  (taskId) =>
    request('get', `/business/approval/dynamic/getWaitDetail?taskId=${taskId}`);

/**
 * 更新并且重新发起流程
 * @param {{activityKey: string,content: object,id: number}} data
 * @returns {{ code:number, data:{ id: number }, msg:string, nowTime:string }}
 */
export const updateAndActivate =
  (data) =>
    request('put', `/business/approval/dynamic/updateAndActivate`, data);

/**
 * 获取流程历史信息
 * @param {{ id:number, pageNum:number, pageSize:number }} params
 * @returns
 */
export const getHistory =
  (params) =>
    request('get', `/business/approval/dynamic/getHistory`, params);

/**
 * 加签
 * @param {{ assignee:string[], isBefore:boolean, reason:string, taskId:string }} data
 * @returns
 */
export const addAssignee =
  (data) =>
    request('put', `/business/bpm/add/assignee`, data);

/**
 * 根据processId获取id
 * @param {string} processId
 * @returns
 */
export const getId =
  (processId) =>
    request('get', `/business/bpm/get/id?processId=${processId}`);
/**
 * 根据processId获取无流程表单数据
 * @param {string} processId
 * @returns
 */
export const getFormDetail = processId => request('POST',`/business/approve/external/data/${processId}`)

export const updateNoProcessData = ({dataId,UpdateApproveData})=>request('POST',`/business/approve/external/data/update/${dataId}`,UpdateApproveData)

export const qrcodeDetail = id => request('post',`/business/approval/dynamic/detail/qr/create?id=${id}`) // 获取二维码