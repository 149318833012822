import { Message } from 'element-ui';
import {getPdfFile} from "../services/check";
import JSEncrypt from 'jsencrypt/bin/jsencrypt.min';
export const getRsaCode = (str)=> {
  let pubKey = localStorage.getItem("publicKey");
  let encryptStr = new JSEncrypt();
  encryptStr.setPublicKey(pubKey); // 设置 加密公钥
  return encryptStr.encrypt(str.toString());// 进行加密
};
export function setCookie(c_name, value, expiredays) {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  document.cookie = c_name + "=" + escape(value) + ";expires = " + exdate.toGMTString() + ";path = /";
}

export function aDownload(blob,fileName){
  let elementA = document.createElement('a');
  elementA.href = window.URL.createObjectURL(blob);
  elementA.download = fileName;
  elementA.click();
  window.URL.revokeObjectURL(elementA.href)
}

export function onDownload(headers, data) {
  try {
    let fileName = window.decodeURI(
        headers["content-disposition"].split("=")[1],
        "UTF-8"
    );
    var blob = new Blob([data], {
      type: headers["content-type"]
    });
    aDownload(blob,fileName)
  }catch (err){
    Message.error('没有数据可导出！')
  }

}

export function flattenList(list,child){
  return (list||[]).reduce((ac,item)=>{
    return [...ac, item].concat(
        flattenList(item[child],child)
    )
  },[])
}

export function filterFunc(arr, word, key) {
  if (word) {
    return arr.filter((ele) => {
      if (ele.content.toLowerCase().includes(word.toLowerCase())) {
        return true;
      } else {
        if (ele[key]) {
          ele[key] = filterFunc(ele[key], word, key);
          if (ele[key].length > 0) {
            return true;
          }
        }
      }
      return false;
    });
  } else {
    return arr;
  }
}

function getChilds(arr, key = 'child') {
  // 辅助函数，不导出
  // 获取一个数组的下一层
  let res = [];
  arr.forEach(ele => {
    if (ele[key] && ele[key].length > 0) {
      res.push(...ele[key]);
    }
  });
  return res;
}

export function findLayer(node, list, key) {
  // 用于计算一个节点在树中的第几层
  let layer = 1;
  while (list.length && !list.some(ele => ele == node)) {
    list = getChilds(list, key)
    layer++;
  }
  return layer;
}

export function removeLeaf(arr, key = 'children') {
  // 去除多余的叶子节点
  return arr.map((e) => {
    if (e[key]) {
      if (e[key].length == 0) {
        delete e[key];
      } else {
        e[key] = removeLeaf(e[key], key);
      }
    }
    return e;
  });
}

// 校验审批人  主要用于流程审批  整个流程至少要有一个审批人
export function validateApprover(activityModel) {
  if(activityModel.filter(e => e.nodeType == 1).every(e => e.userList&&e.userList.length == 0)){
    Message.warning("请至少选择一个审批人");
    return false;
  }
  for (const ele of activityModel) {
    if (ele.isOwnSetting === 0 && ele.userList && ele.userList.length) {
      if (ele.userList.some((e) => e.id == -1)) {
        Message.warning("请选择人员，或删除空的节点");
        return false;
      }
    }
  }
  return true;
}

export function operatorDialog(that,label,title,okFn, {placeholder, required} = {placeholder: '', required: false}){
  that.$prompt(label,title,{
    confirmButtonText:'确定',
    cancelBUttonText:'取消',
    inputType:'textarea',
    inputPlaceholder:placeholder || '请输入理由',
    inputValidator:(value)=>{
      if(value&&value.length>80){
        return '长度不能超过80'
      }
      if(required && !value){
        return `${label}必填`
      }
      // if(required && !value.length){
      //   return `${label}必填`
      // }
    }
  }).then(({value})=>{
    okFn(value)
  })
}

export function isIE(){
  if (!!window.ActiveXObject || "ActiveXObject" in window) {
    return true;
  } else {
    return false;
  }
}

export function previewPDF(file){
  const url = 'business/file/get/' + file.id;
  return new Promise((resolve,reject)=>{
    getPdfFile(url, {}).then(res => {
      if (res.data.status === 0) {
        Message.warning('文件正在处理中，请稍后预览...')
        reject()
      } else if (res.data.status === 1) {
        let photos = [];
        let url = res.data.url.split('.pdf')[0]
        for (let i = 0; i < res.data.total; i++) {
          photos.push(url + '-' + (i + 1) + '.jpg');
        }
        resolve(photos)
      } else {
        Message.error('文件处理失败，请重新上传')
        reject()
      }
    })
  })
}

/**
 * 根据nodeName折叠流程   有空了就把detail-flow里面那个方法也改一下
 * @param {Array} list
 * @returns
 */
export function foldNodeList(list) {
  let activityNodeList = [];
  let nodeArr = [];
  for (let item of list) {
    let index = nodeArr.indexOf(item.nodeName)
    if (index > -1) {
      if(item.userList && item.userList.length){
        // 不管会签或者是或签 只要有人拒绝了  节点状态就是拒绝    会签：所有人都通过了节点才会通过(即后面的user状态需要覆盖前面的user的状态)，或签：有一个人同意了节点就是通过的
        // nodeAuditType ---0---或签   ----1----会签
        let type = null;
        if (item.dealType == '1') {
          type = '1'
        } else if (item.nodeAuditType == 1) {  // 会签
          type = [item.dealType, activityNodeList[index].dealType].some(value => value == '1') ? '1' : ([item.dealType, activityNodeList[index].dealType].some(value => value == null) ? null : '0');
        } else if (item.nodeAuditType == 0) {  // 或签
          type = item.dealType == '0' ? '0' : activityNodeList[index].dealType
        } else {
          type = activityNodeList[index].dealType;
        }
        activityNodeList[index].dealType = type;
        item.userList[0].dealType = item.dealType;
        item.userList[0].dealTime = item.dealTime;
        item.userList[0].dealReson = item.dealReson;
        item.userList[0].dealRemark = item.dealRemark;
        activityNodeList[index].userList = activityNodeList[index].userList.concat(item.userList)
      }
    } else {
      nodeArr.push(item.nodeName)
      activityNodeList.push(JSON.parse(JSON.stringify(item)));
      activityNodeList[activityNodeList.length - 1].userList = [];
      if (item.userList) {
        if (item.userList.length === 1) {
          item.userList[0].dealType = item.dealType
          item.userList[0].dealTime = item.dealTime;
          item.userList[0].dealReson = item.dealReson;
          item.userList[0].dealRemark = item.dealRemark;
        } else {
          item.userList = item.userList.map(value => {
            value.dealType = item.nodeType == 2 && item.dealType == 0 ? '0' : null
            value.dealTime = item.dealTime
            return value
          })
        }
        activityNodeList[activityNodeList.length - 1].userList = activityNodeList[activityNodeList.length - 1].userList.concat(item.userList)
      } else {
        // activityNodeList[activityNodeList.length - 1].userList.push({ name: '' });
        activityNodeList[activityNodeList.length - 1].userList = [];
      }
    }
  }
  return activityNodeList;
}

export function getShopCode(shopNo){
  let selectShopNo = shopNo && shopNo.length > 0 ? shopNo[shopNo.length - 1]:''
  return  selectShopNo.slice(selectShopNo.indexOf('(')+1,-1)
}

export class Time{
  constructor(time) {
   this.time = time?new Date(time):new Date();
   this.year = this.time.getFullYear();
   this.month = (''+(this.time.getMonth() + 1)).padStart(2,'0');
   this.day = (''+this.time.getDate()).padStart(2,'0');
   this.hour = (''+this.time.getDate()).padStart(2,'0');
   this.minute = (''+this.time.getDate()).padStart(2,'0');
   this.second = (''+this.time.getDate()).padStart(2,'0');
  }

  format = (fmt="yyyy-MM-dd hh:mm:ss")=>{
    let res = fmt;
    res = res.replace(/y+/,this.year);
    res = res.replace(/M+/,this.month);
    res = res.replace(/d+/,this.day);
    res = res.replace(/h+/,this.hour);
    res = res.replace(/m+/,this.minute);
    res = res.replace(/s+/,this.second);
    return res;
  }
}

export function filterDayDate({maxDate, minDate }) {
  if (!maxDate) {
    this.maxDate = maxDate
    this.minDate = minDate
  } else {
    this.minDate = this.maxDate = null
  }
}
export function disabledDayDate (spec) {
  return (time) => {
    let date = this.minDate
    let start = new Date(date).getTime()
    if(date) {
        return time.getTime() > start + spec || time.getTime() < start - spec
    }
  }
}

export function isJSON (str) {
  if (typeof str === "string") {
    try {
      var obj = JSON.parse(str);
      if (obj && typeof obj === "object") {
        return true
      } else {
        return false
      }
    } catch (e) {
      return false;
    }
  } else {
    return false;
  }
}