import Vue from "vue";
import VueRouter from "vue-router";
import main from "../pages/main";
import { initRoute } from "./initRoute";
import dashboard from "../pages/tour-module/data-reports/dashboard";
Vue.use(VueRouter);

// 欢迎页面
const Login = () => import( /* webpackChunkName: "login" */ '@/pages/login') // 欢迎页面
 
const Home = () => import(/* webpackChunkName: "Home" */ "@/pages/Home"); // 主要页面

const Callback = () => import("@/pages/callback");

const Main = () => import("@/pages/main");

// 业务管理
const Cashier = () =>
  import(
    /* webpackChunkName: "businessModule" */ "@/pages/businessModule/cashier"
  ); // 收银员
const CashierCard = () =>
  import(
    /* webpackChunkName: "businessModule" */ "@/pages/businessModule/cashierCard"
  ); // 收银员办卡
const Reservation = () =>
  import(
    /* webpackChunkName: "businessModule" */ "@/pages/businessModule/reservation"
  ); // 预约管理

// 审核管理
const ReportMarket = () =>
  import(
    /* webpackChunkName: "checkModule" */ "@/pages/checkModule/checkCMS/reportMarket"
  ); // 上报销售
const ReportMarketCheck = () =>
  import(
    /* webpackChunkName: "checkModule" */ "@/pages/checkModule/check/reportMarketCheck"
  ); // 上报销售审核
const CheckOutAllow = () =>
  import(
    /* webpackChunkName: "checkModule" */ "@/pages/checkModule/checkCMS/outAllow"
  ); // 出入许可
const OutAllowCheck = () =>
  import(
    /* webpackChunkName: "checkModule" */ "@/pages/checkModule/check/outAllowCheck"
  ); // 出入许可审核
const Papers = () =>
  import(
    /* webpackChunkName: "checkModule" */ "@/pages/checkModule/checkCMS/papers"
  ); //证件办理
const PapersCheck = () =>
  import(
    /* webpackChunkName: "checkModule" */ "@/pages/checkModule/check/papersCheck"
  ); //证件办理审核
const Realname = () =>
  import(
    /* webpackChunkName: "checkModule" */ "@/pages/checkModule/checkCMS/realname"
  ); // 实名认证
const RealnameCheck = () =>
  import(
    /* webpackChunkName: "checkModule" */ "@/pages/checkModule/check/realnameCheck"
  ); // 实名认证审核
const Shopowner = () =>
  import(
    /* webpackChunkName: "checkModule" */ "@/pages/checkModule/checkCMS/shopowner"
  ); // 店长申请
const ShopownerCheck = () =>
  import(
    /* webpackChunkName: "checkModule" */ "@/pages/checkModule/check/shopownerCheck"
  ); // 上报销售审核
const MerchantSreen = () =>
  import(
    /* webpackChunkName: "checkModule" */ "@/pages/checkModule/check/merchantScreenCaste"
  ); // 商户投屏
const MerchantSreenDetail = () =>
  import(
    /* webpackChunkName: "checkModule" */ "@/pages/checkModule/check/mechantScreenDetail"
  ); // 商户投屏
const ApplyForCashier = () =>
  import(
    /* webpackChunkName: "checkModule" */ "@/pages/checkModule/check/applyForCashier"
  ); // 申请收银员
const ApplyForCashierCheck = () =>
  import(
    /* webpackChunkName: "checkModule" */ "@/pages/checkModule/check/applyCashierCheck"
  ); // 申请收银员审核

// 报表
const Report = () =>
  import(
    /* webpackChunkName: "statement" */ "@/pages/statement/statement/report"
  ); //上报销售审核
const ReportInfo = () =>
  import(
    /* webpackChunkName: "statement" */ "@/pages/statement/info/reportInfo"
  ); //上报销售报表详情
const OutAllow = () =>
  import(
    /* webpackChunkName: "statement" */ "@/pages/statement/statement/outAllow"
  ); // 出入许可管理
const OutAllowInfo = () =>
  import(
    /* webpackChunkName: "statement" */ "@/pages/statement/info/outAllowInfo"
  ); // 出入许可报表详情
const Certificate = () =>
  import(
    /* webpackChunkName: "statement" */ "@/pages/statement/statement/certificate"
  ); // 证件管理
const CertificateInfo = () =>
  import(
    /* webpackChunkName: "statement" */ "@/pages/statement/info/certificateInfo"
  ); // 证件管理
const Autonym = () =>
  import(
    /* webpackChunkName: "statement" */ "@/pages/statement/statement/autonym"
  ); // 实名认证
const AutonymInfo = () =>
  import(
    /* webpackChunkName: "statement" */ "@/pages/statement/info/autonymInfo"
  ); // 实名认证报表详情
const CardVerified = () =>
  import(
    /* webpackChunkName: "statement" */ "@/pages/statement/statement/cardVerified"
  ); // 核销列表
const ReturnCard = () =>
  import(
    /* webpackChunkName: "statement" */ "@/pages/statement/statement/returnCard"
  ); // 退券列表
const ActivityApply = () =>
  import(
    /* webpackChunkName: "statement" */ "@/pages/statement/statement/activityApply"
  ); // 预约报名
const CheckCard = () =>
  import(
    /* webpackChunkName: "statement" */ "@/pages/statement/statement/checkCard"
  ); // 预约办卡
// 审批报表
const approvalStatistics = () =>
  import("@/pages/statement/approval-report/statistics/index");
const statisticsDetail = () =>
  import("@/pages/statement/approval-report/statistics/detail");
const approvalTypeStatistics = () =>
  import("@/pages/statement/approval-report/type-statistics");
const approvalDepartmentStatistics = () =>
  import("@/pages/statement/approval-report/department-statistics");
const approvalMemberStatistics = () =>
  import("@/pages/statement/approval-report/member-statistics");
const memberDetailStatistics = () =>
  import("@/pages/statement/approval-report/member-detail-statistics");

// 商户管理
const MainShop = () => import( /* webpackChunkName: "shopModule" */ '@/pages/shopModule/shop/mainShop/index.vue') // 商户管理
const CouponRefund = () => import( /* webpackChunkName: "shopModule" */ '@/pages/shopModule/shop/couponRefund/index.vue') // 优惠券返款
const ShopAuthority = () => import( /* webpackChunkName: "shopModule" */ '@/pages/shopModule/shop/shopAuthority') // 商户管理
const ShopWarrant = () => import( /* webpackChunkName: "shopModule" */ '@/pages/shopModule/shop/warrant') // 授权管理
const Deposit = () => import( /* webpackChunkName: "shopModule" */ '@/pages/shopModule/deposit/deposit') // 押金设置
const CashierDeposit = () => import( /* webpackChunkName: "shopModule" */ '@/pages/shopModule/deposit/cashierDeposit') // 收银员押金设置

// 企业管理
const MainMall = () => import( /* webpackChunkName: "mallManage" */ '@/pages/mallModule/mainMall.vue')
const MallAuthority = () => import( /* webpackChunkName: "mallManage" */ '@/pages/mallModule/mallAuthority')

// 企业商城
const ShopOnline = () => import( /* webpackChunkName: "mallModule" */ '@/pages/mallModule/shopOnline.vue')
const ShopExamine = () => import( /* webpackChunkName: "mallModule" */ '@/pages/mallModule/ShopExamine.vue')
const ShopAdd = () => import( /* webpackChunkName: "mallModule" */ '@/pages/mallModule/components/add.vue')
const ShopDetail = () => import( /* webpackChunkName: "mallModule" */ '@/pages/mallModule/components/detail.vue')

// 订单管理
const GoodsOrder = () => import( /* webpackChunkName: "mallModule" */ '@/pages/mallModule/goodsOrder.vue')
const GoodsOrderSend = () => import( /* webpackChunkName: "mallModule" */ '@/pages/mallModule/goodsOrderSend.vue')
const GoodsOrderReturn = () => import( /* webpackChunkName: "mallModule" */ '@/pages/mallModule/goodsOrderReturn.vue')
const GoodsDetail = () => import( /* webpackChunkName: "mallModule" */ '@/pages/mallModule/goodsDetail.vue')
const GoodsReturnDetail = () => import( /* webpackChunkName: "mallModule" */ '@/pages/mallModule/goodsReturnDetail.vue')
const GoodsQuery = () => import( /* webpackChunkName: "mallModule" */ '@/pages/mallModule/goodsQuery.vue')

// 系统管理
const Department = () =>
  import(
    /* webpackChunkName: "systemModule" */ "@/pages/systemModule/structure/department"
  ); // 部门管理
const ShopList = () =>
  import(
    /* webpackChunkName: "systemModule" */ "@/pages/systemModule/structure/shopList"
  ); // 店铺列表
const Position = () =>
  import(
    /* webpackChunkName: "systemModule" */ "@/pages/systemModule/structure/position"
  ); // 职位管理
const Role = () =>
  import(
    /* webpackChunkName: "systemModule" */ "@/pages/systemModule/privilege/role"
  ); // 角色管理
const CreateRole = () =>
  import(
    /* webpackChunkName: "systemModule" */ "@/pages/systemModule/privilege/createRole"
  ); // 角色管理-创建角色
const CompileRole = () =>
  import(
    /* webpackChunkName: "systemModule" */ "@/pages/systemModule/privilege/compileRole"
  ); // 用户管理-编辑角色
const User = () =>
  import(
    /* webpackChunkName: "systemModule" */ "@/pages/systemModule/privilege/user"
  ); // 用户管理
const CreateUser = () =>
  import(
    /* webpackChunkName: "systemModule" */ "@/pages/systemModule/privilege/createUser"
  ); // 用户管理-创建用户
const UserAuthority = () =>
  import(
    /* webpackChunkName: "systemModule" */ "@/pages/systemModule/privilege/userAuthority"
  ); // 用户管理-授权
const OperateLog = () =>
  import(
    /* webpackChunkName: "systemModule" */ "@/pages/systemModule/log/operateLog"
  ); // 操作日志
const BiLog = () =>
  import(
    /* webpackChunkName: "systemModule" */ "@/pages/systemModule/log/biLog"
  ); // BI日志
const SysLog = () =>
  import(
    /* webpackChunkName: "systemModule" */ "@/pages/systemModule/log/sysLog"
  ); // 系统日志
const PayManage = () =>
  import(
    /* webpackChunkName: "systemModule" */ "@/pages/systemModule/payManage/index.vue"
  ); // 支付管理

// 巡场管理
const tourAreaConfig = () =>
  import("@/pages/tour-module/tour-config/tourAreaConfig"); // 巡场区域配置
const tourTypeConfig = () =>
  import("@/pages/tour-module/tour-config/tourTypeConfig"); // 巡场类型配置
const tourParameterConfig = () =>
  import("@/pages/tour-module/tour-config/tourParameterConfig"); // 巡场参数配置
const tourMain = () => import("@/pages/tour-module/management/index"); // 巡场工单管理-index
const tourEdit = () => import("@/pages/tour-module/management/edit"); // 巡场工单管理-edit
const tourMyList = () => import("@/pages/tour-module/management/MyList"); // 巡场工单管理-mylist
const tourTotalList = () => import("@/pages/tour-module/management/TotalList"); // 巡场工单管理-totallist
const tourDetail = () => import("@/pages/tour-module/management/detail"); // 巡场工单管理-detail
const departmentStatistic = () =>
  import("@/pages/tour-module/data-reports/departmentStatistic"); // 巡场管理-部门统计
const tourDashboard = () =>
  import("@/pages/tour-module/data-reports/dashboard"); // 巡场管理-部门统计
const typeStatistic = () =>
  import("@/pages/tour-module/data-reports/typeStatistic"); // 巡场管理-类型统计
const workorderStatistic = () =>
  import("@/pages/tour-module/data-reports/workOrderStatistic"); // 巡场管理-工单统计
/* 购物商城 */
const paramsConfig = () => import('@/pages/shopMall/basicData/paramsConfig'); // 参数配置
const serviceTag = () => import('@/pages/shopMall/basicData/serviceTag'); // 服务标签
const expressCompany = () => import('@/pages/shopMall/basicData/expressCompany'); // 快递公司
const expressTemplate = () => import('@/pages/shopMall/basicData/expressTemplate'); // 快递模板
const handleReason = () => import('@/pages/shopMall/basicData/handleReason'); // 快递模板
const expressTemplateEdit = () => import('@/pages/shopMall/basicData/expressTemplateEdit'); // 快递模板
const shopCategory = () => import('@/pages/shopMall/shopManage/shopCategory'); // 商品分类
const shopManage = () => import('@/pages/shopMall/shopManage/shopManage'); // 商品管理
const shopAudit = () => import('@/pages/shopMall/shopManage/shopAudit'); // 商品审核
const shopAdd= () => import('@/pages/shopMall/shopManage/shopEidt'); // 商品编辑
const shopDetail= () => import('@/pages/shopMall/shopManage/shopDetail'); // 商品详情
const goodsTag = () => import('@/pages/shopMall/basicData/goodsTag')
/**
 * isMain 是否是主业务模块
 *
 */
export const _routes = [
  {
    path: "/checkModule",
    component: Home,
    meta: {
      id: 201,
      isMainMenu: true,
    },
    children: [
      {
        // 审核管理
        path: "/checkModule/check",
        component: Main,
        meta: {
          id: 202,
        },
        children: [
          {
            name: "上报销售",
            path: "/checkModule/check/reportMarket",
            component: ReportMarket,
            meta: {
              id: 203,
            },
            children: [
              {
                name: "上报销售审核",
                path: "/checkModule/check/reportMarket/check",
                component: ReportMarketCheck,
              },
            ],
          },
          {
            name: "证件办理",
            path: "/checkModule/check/papers",
            component: Papers,
            meta: {
              id: 205,
            },
            children: [
              {
                name: "证件办理审核",
                path: "/checkModule/check/papers/check",
                component: PapersCheck,
              },
            ],
          },

          {
            name: "实名认证",
            path: "/checkModule/check/realname",
            component: Realname,
            meta: {
              id: 206,
            },
            children: [
              {
                name: "实名认证审核",
                path: "/checkModule/check/realname/check",
                component: RealnameCheck,
              },
            ],
          },
          {
            name: "申请收银员",
            path: "/checkModule/check/cashier",
            component: ApplyForCashier,
            meta: {
              id: 508,
            },
            children: [
              {
                name: "申请收银员审核",
                path: "/checkModule/check/cashier/check",
                component: ApplyForCashierCheck,
              },
            ],
          },

          {
            name: "店长申请",
            path: "/checkModule/check/shopowner",
            component: Shopowner,
            meta: {
              id: 207,
            },
            children: [
              {
                name: "店长申请审核",
                path: "/checkModule/check/shopowner/check",
                component: ShopownerCheck,
              },
            ],
          },
          {
            name: "商户投屏",
            path: "/checkModule/check/merchant",
            component: MerchantSreen,
            meta: {
              id: 503,
            },
            children: [
              {
                name: "商户投屏审核详情",
                path: "/checkModule/check/merchant/check",
                component: MerchantSreenDetail,
              },
            ],
          },
        ],
      },
      {
        path: "/checkModule/examine",
        component: Main,
        meta: {
          id: 229,
        },
        children: [
          {
            name: "待办事项",
            path: "/checkModule/examine/agent",
            component: () => import("@/pages/checkModule/examine/agent.vue"),
            meta: {
              id: 230,
            },
          },
          {
            name: "我审批的",
            path: "/checkModule/examine/approval",
            component: () => import("@/pages/checkModule/examine/approval.vue"),
            meta: {
              id: 232,
            },
          },
          {
            name: "抄送我的",
            path: "/checkModule/examine/bcc",
            component: () => import("@/pages/checkModule/examine/bcc.vue"),
            meta: {
              id: 233,
            },
          },
          {
            name: "我发起的",
            path: "/checkModule/examine/initiation",
            component: () =>
              import("@/pages/checkModule/examine/initiation.vue"),
            meta: {
              id: 231,
            },
          },
        ],
      },
      {
        path: "/checkModule/property-service",
        component: Main,
        meta: {
          id: 392,
        },
        children: [
          {
            name: "物业报修审核",
            path: "/checkModule/property-service/property-repair-review",
            component: () =>
              import(
                "@/pages/checkModule/property-service/property-repair-review/index.vue"
              ),
            children: [
              {
                name: "详情",
                path:
                  "/checkModule/property-service/property-repair-review/detail/:id/:status",
                component: () =>
                  import(
                    "@/pages/checkModule/property-service/property-repair-review/detail.vue"
                  ),
              },
            ],
            meta: {
              id: 393,
            },
          },
          {
            name: "物业报修确认",
            path: "/checkModule/property-service/property-repair-confirm",
            component: () =>
              import(
                "@/pages/checkModule/property-service/property-repair-confirm/index.vue"
              ),
            children: [
              {
                name: "详情",
                path:
                  "/checkModule/property-service/property-repair-confirm/detail/:id?",
                component: () =>
                  import(
                    "@/pages/checkModule/property-service/property-repair-confirm/detail.vue"
                  ),
              },
            ],
            meta: {
              id: 394,
            },
          },
          {
            name: "运营报修确认",
            path: "/checkModule/property-service/operation-repair-confirm",
            component: () =>
              import(
                "@/pages/checkModule/property-service/operation-repair-confirm/index.vue"
              ),
            children: [
              {
                name: "详情",
                path:
                  "/checkModule/property-service/operation-repair-confirm/detail/:id",
                component: () =>
                  import(
                    "@/pages/checkModule/property-service/operation-repair-confirm/detail.vue"
                  ),
              },
            ],
            meta: {
              id: 395,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/statement",
    component: Home,
    meta: {
      id: 208,
      isMainMenu: true,
    },
    children: [
      {
        path: "/statement/log",
        component: () => import("@/pages/statement/log/inspectLog.vue"),
        meta: {
          id: 420,
        },
      },
      {
        name: "核验日志",
        path: "/statement/log/inspectLog",
        component: () => import("@/pages/statement/log/inspectLog.vue"),
      },
      {
        path: "/statement/static",
        component: Main,
        meta: {
          id: 209,
        },
        children: [
          {
            name: "上报销售审核",
            path: "/statement/report",
            component: Report,
            meta: {
              id: 210,
            },
          },
          {
            name: "上报销售报表详情",
            path: "/statement/reportInfo",
            component: ReportInfo,
          },
          {
            name: "证件管理",
            path: "/statement/certificate",
            component: Certificate,
            meta: {
              id: 212,
            },
          },
          {
            name: "证件管理详情",
            path: "/statement/certificateInfo",
            component: CertificateInfo,
          },
          {
            name: "实名认证",
            path: "/statement/autonym",
            component: Autonym,
            meta: {
              id: 213,
            },
          },
          {
            name: "实名认证报表详情",
            path: "/statement/autonymInfo",
            component: AutonymInfo,
          },
          {
            name: "卡券核销",
            path: "/statement/cardVerified",
            component: CardVerified,
            meta: {
              id: 219,
            },
          },
          {
            name: "退券统计",
            path: "/statement/returnCard",
            component: ReturnCard,
            meta: {
              id: 220,
            },
          },
          {
            name: "预约报名",
            path: "/statement/activityApply",
            component: ActivityApply,
            meta: {
              id: 227,
            },
          },
          {
            name: "预约办卡",
            path: "/statement/checkCard",
            component: CheckCard,
            meta: {
              id: 226,
            },
          },
          {
            name: "活动报名",
            path: "/statement/static/activity",
            component: () => import("@/pages/statement/statement/activity.vue"),
            meta: {
              id: 429,
            },
          },
          {
            name: "卡券核销明细",
            path: "/statement/static/couponDetail",
            component: () =>
              import(
                "@/pages/statement/statement/coupon-verification-details.vue"
              ),
            meta: {
              id: 456,
            },
          },
          {
            name: "卡券核销汇总",
            path: "/statement/static/couponSummary",
            component: () =>
              import(
                "@/pages/statement/statement/coupon-verification-summary.vue"
              ),
            meta: {
              id: 457,
            },
          },
        ],
      },
      {
        path: "/statement/approvalReport",
        component: Main,
        meta: {
          id: 435,
        },
        children: [
          {
            /**
             * 审批统计
             */
            path: "/statement/approvalReport/statistic",
            component: approvalStatistics,
            meta: {
              id: 436,
            },
            children: [
              {
                // 详情
                path: "/statement/approvalReport/statistic/detail/:typeId/:id",
                component: statisticsDetail,
              },
            ],
          },
          {
            /**
             * 审批部门统计
             */
            path: "/statement/approvalReport/departmentStatistics",
            component: approvalDepartmentStatistics,
            meta: {
              id: 438,
            },
          },
          {
            /**
             * 审批类型统计
             */
            path: "/statement/approvalReport/typeStatistics",
            component: approvalTypeStatistics,
            meta: {
              id: 437,
            },
          },
          {
            /**
             * 成员统计
             */
            path: "/statement/approvalReport/memberStatistics",
            component: approvalMemberStatistics,
            meta: {
              id: 439,
            },
          },
          {
            /**
             * 成员明细统计
             */
            path: "/statement/approvalReport/memberDetailStatistics",
            component: memberDetailStatistics,
            meta: {
              id: 440,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/shopModule",
    component: Home,
    meta: {
      id: 214,
      isMainMenu: true,
    },
    children: [
      {
        // 商户管理
        path: "/shopModule/shop",
        component: Main,
        meta: {
          id: 215,
        },
        children: [
          {
            name: "商户管理",
            path: "/shopModule/shop/mainShop",
            meta: {
              id: 216,
            },
            component: MainShop,
            children: [
              {
                name: "授权",
                path: "/shopModule/shop/mainShop/authority",
                component: ShopAuthority,
              },
            ],
          },
          {
            name: "授权管理",
            path: "/shopModule/shop/mainShop/warrant",
            component: ShopWarrant,
            meta: {
              id: 461,
            },
          },
          {
            name: "优惠券返款",
            path: "/shopModule/shop/couponRefund",
            component: CouponRefund,
            meta: {
              id: 515,
            },
          },
        ],
      },
      {
        // 押金管理
        path: "/shopModule/deposit",
        component: Main,
        meta: {
          id: 217,
        },
        children: [
          {
            name: "证件押金",
            path: "/shopModule/deposit/certificate",
            component: Deposit,
            meta: {
              id: 218,
            },
          },
          {
            name: "收银员押金",
            path: "/shopModule/deposit/cashier",
            component: CashierDeposit,
            meta: {
              id: 228,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/mallManage',
    component: Home,
    meta:{
      id:534,
      isMainMenu:true,
    },
    children: [
      {
        path:'/mallManage/mall',
        component:Main,
        meta:{
          id:536
        },
        children: [
          {
            name: '企业管理',
            path: '/mallManage/mall/mainMall',
            meta:{
              id:537
            },
            component: MainMall,
            children: [
              {
                name: '授权',
                path: '/mallManage/mall/mainMall/authority',
                component: MallAuthority
              }
            ]
          },
        ]
      },
    ]
  },
  {
    path: '/mallModule',
    component: Home,
    meta:{
      id:535,
      isMainMenu:true,
    },
    children: [
      {
        path:'/mallModule/goods',
        component:Main,
        meta:{
          id:562
        },
        children: [
          {
            name: '订单管理',
            path: '/mallModule/goods/order',
            meta:{
              id:563
            },
            component: GoodsOrder,
            children: [
              {
                name: '订单详情',
                path: '/mallModule/goods/detail',
                component: GoodsDetail
              },
            ]
          },
          {
            name: '订单查询',
            path: '/mallModule/goods/query',
            meta:{
              id:564
            },
            component: GoodsQuery,
          },
          {
            name: '线下提货',
            path: '/mallModule/goods/send',
            meta:{
              id:565
            },
            component: GoodsOrderSend,
            children: [
              {
                name: '订单详情',
                path: '/mallModule/goods/detail',
                component: GoodsDetail
              },
            ]
          },
          {
            name: '退货管理',
            path: '/mallModule/goods/return',
            meta:{
              id:566
            },
            component: GoodsOrderReturn,
            children: [
              {
                name: '订单详情',
                path: '/mallModule/goods/returnDetail',
                component: GoodsReturnDetail
              },
            ]
          },
        ]
      },
      {
        path:'/mallModule/shop',
        component:Main,
        meta:{
          id:538
        },
        children: [
          {
            name: '店铺开店',
            path: '/mallModule/shop/online',
            meta:{
              id:539
            },
            component: ShopOnline,
            children: [
              {
                name: '店铺开店',
                path: '/mallModule/shop/online/add',
                component: ShopAdd
              },
              {
                name: '店铺信息',
                path: '/mallModule/shop/online/detail',
                component: ShopDetail
              }
            ]
          },
          {
            name: '店铺审核',
            path: '/mallModule/shop/examine',
            meta:{
              id:540
            },
            component: ShopExamine,
            children: [
              {
                name: '店铺信息',
                path: '/mallModule/shop/online/detail',
                component: ShopDetail
              }
            ]
          },
        ]
      },
      {
        // 基础数据
        path: "/shop-mall/basic-data",
        component: Main,
        meta: {
          id: 541,
        },
        children: [
          {
            name: "参数配置",
            path: "/shop-mall/basic-data/params-config",
            component: paramsConfig,
            meta: {
              id: 542,
            },
          },
          {
            name: "服务标签",
            path: "/shop-mall/basic-data/service-tag",
            component: serviceTag,
            meta: {
              id: 543,
            },
          },
          {
            name: "快递公司",
            path: "/shop-mall/basic-data/express-company",
            component: expressCompany,
            meta: {
              id: 545,
            },
          },
          {
            name: "快递模版",
            path: "/shop-mall/basic-data/express-template",
            component: expressTemplate,
            meta: {
              id: 544,
            },
          },
          {
            name: "理由配置",
            path: "/shop-mall/basic-data/handle-reason",
            component: handleReason,
            meta: {
              id: 561,
            },
          },
          {
            name: "商品标签",
            path: "/shop-mall/basic-data/goods-tag",
            component: goodsTag,
            meta: {
              id: 568,
            },
          },
          {
            name: '模板编辑',
            path: '/shop-mall/basic-data/express-template/edit/:id?',
            component: expressTemplateEdit
          }
        ],
      }, // 迭代
      {
        path: "/shop-mall/shop-manage",
        component: Main,
        meta: {
          id: 546,
        },
        children: [
          {
            name: "商品类别",
            path: "/shop-mall/shop-manage/shop-category",
            component: shopCategory,
            meta: {
              id: 547,
            },
          },
          {
            name: "商品管理",
            path: "/shop-mall/shop-manage/shop-manage",
            component: shopManage,
            meta: {
              id: 548,
            },
          },
          {
            name: "商品审核",
            path: "/shop-mall/shop-manage/shop-audit",
            component: shopAudit,
            meta: {
              id: 549,
            }
          },
          {
            name: '商品新增',
            path: '/shop-mall/shop-manage/shop-manage/edit/:id?',
            component: shopAdd
          },
          {
            name: '商品新增',
            path: '/shop-mall/shop-manage/shop-manage/detail/:id',
            component: shopDetail
          }
        ]
      }
    ]
  },
  {
    path: '/systemModule',
    component: Home,
    meta: {
      id: 6,
      isMainMenu: true,
    },
    children: [
      {
        // 组织架构
        path: "/systemModule/organization",
        component: Main,
        meta: {
          id: 7,
        },
        children: [
          {
            name: "部门",
            path: "/systemModule/department",
            component: Department,
            meta: {
              id: 10,
            },
          },
          {
            name: "店铺列表",
            path: "/systemModule/shopList",
            component: ShopList,
            meta: {
              id: 504,
            },
          },
          {
            name: "职位管理",
            path: "/systemModule/position",
            component: Position,
            meta: {
              id: 11,
            },
          },
        ],
      },
      {
        // 权限管理
        path: "/systemModule/auth",
        component: Main,
        meta: {
          id: 8,
        },
        children: [
          {
            name: "角色管理",
            path: "/systemModule/auth/role",
            component: Role,
            meta: {
              id: 12,
            },
            children: [
              {
                name: "角色管理-创建角色",
                path: "/systemModule/auth/role/create",
                component: CreateRole,
              },
              {
                name: "角色管理-编辑角色",
                path: "/systemModule/auth/role/compile",
                component: CompileRole,
              },
            ],
          },
          {
            name: "用户管理",
            path: "/systemModule/auth/user",
            component: User,
            meta: {
              id: 13,
            },
            children: [
              {
                name: "用户管理-创建用户",
                path: "/systemModule/auth/user/create",
                component: CreateUser,
              },
              {
                name: "用户管理-授权",
                path: "/systemModule/auth/user/authority",
                component: UserAuthority,
              },
            ],
          },
          {
            name: "安保管理",
            path: "/systemModule/security",
            component: () =>
              import("@/pages/systemModule/privilege/security.vue"),
            meta: {
              id: 419,
            },
          },
        ],
      },
      {
        // 人事管理
        path: "/systemModule/personnel",
        component: Main,
        meta: {
          id: 15,
        },
        children: [
          {
            name: "岗位配置",
            path: "/systemModule/personnel/position",
            component: () =>
              import("@/pages/systemModule/personnel/position.vue"),
            meta: {
              id: 16,
            },
          },
          {
            name: "职级配置",
            path: "/systemModule/personnel/grade",
            component: () => import("@/pages/systemModule/personnel/grade.vue"),
            meta: {
              id: 17,
            },
          },
        ],
      },
      {
        // APP更新
        path: "/systemModule/App/appUpdate",
        component: () => import("@/pages/systemModule/App/appUpdate.vue"),
        meta: {
          id: 418,
        },
      },
      {
        //参数设置
        path: "/systemModule/paramConfig",
        component: Main,
        meta: {
          id: 421,
        },
        children: [
          {
            name: "关联表单",
            path: "/systemModule/relationForm",
            component: () =>
              import("@/pages/systemModule/relationForm/index.vue"),
            meta: {
              id: 516,
            },
          },
          {
            name: "功能配置",
            path: "/systemModule/paramConfig",
            component: () =>
              import("@/pages/systemModule/paramConfig/index.vue"),
            meta: {
              id: 474,
            },
          },
          {
            name: "上报销售配置",
            path: "/systemModule/reportSales",
            component: () =>
              import("@/pages/systemModule/reportSales/index.vue"),
            meta: {
              id: 475,
            },
          },
          {
            name: "付款方式配置",
            path: "/systemModule/paymentType",
            component: () =>
              import("@/pages/systemModule/paymentType/index.vue"),
            meta: {
              id: 500,
            },
          },
        ],
      },
      {
        // 菜单管理
        path: "/systemModule/menuConfig",
        component: () => import("@/pages/systemModule/menu/menuConfig.vue"),
        meta: {
          id: 428,
        },
      },
      {
        path: "/systemModule/Bilog",
        component: BiLog,
        meta: {
          id: 463,
        },
      },
      {
        path: "/systemModule/syslog",
        component: SysLog,
        meta: {
          id: 505,
        },
      },
      {
        path: "/systemModule/payManage",
        component: PayManage,
        meta: {
          id: 511,
        },
      },
    ],
  },
  {
    //  '业务管理'
    path: "/businessModule",
    component: Home,
    meta: {
      id: 221,
      isMainMenu: true,
    },
    children: [
      {
        // 业务管理
        path: "/businessModule/bubsiness",
        component: Main,
        meta: {
          id: 222,
        },
        children: [
          {
            name: "收银员管理",
            path: "/businessModule/cashier",
            component: Cashier,
            meta: {
              id: 225,
            },
          },
          {
            name: "收银员办卡",
            path: "/businessModule/cashierCard",
            component: CashierCard,
            meta: {
              id: 224,
            },
          },
          {
            name: "预约管理",
            path: "/businessModule/reservation",
            component: Reservation,
            meta: {
              id: 223,
            },
          },
        ],
      }, // 迭代
      {
        path: "/businessModule/merchant",
        component: Main,
        meta: {
          id: 496,
        },
        children: [
          {
            name: "商户罚单",
            path: "/businessModule/merchant/ticket",
            component: () =>
              import("@/pages/businessModule/merchant/ticket.vue"),
            meta: {
              id: 497,
            },
            children: [
              {
                name: "商户罚单-新增罚单",
                path: "/businessModule/merchant/ticket/add",
                component: () =>
                  import("@/pages/businessModule/merchant/children/add.vue"),
              },
              {
                name: "商户罚单-罚单详情",
                path: "/businessModule/merchant/ticket/detail",
                component: () =>
                  import("@/pages/businessModule/merchant/children/detail.vue"),
              },
            ],
          },
          {
            name: "商户核验",
            path: "/businessModule/merchant/verify",
            component: () =>
              import("@/pages/businessModule/merchant/verify.vue"),
            meta: {
              id: 498,
            },
            children: [
              {
                name: "商户罚单-罚单详情",
                path: "/businessModule/merchant/verify/detail",
                component: () =>
                  import("@/pages/businessModule/merchant/children/detail.vue"),
              },
            ],
          },
          {
            name: "罚单配置",
            path: "/businessModule/merchant/configure",
            component: () =>
              import("@/pages/businessModule/merchant/configure.vue"),
            meta: {
              id: 499,
            },
            children: [
              {
                name: "罚单配置-罚金项",
                path: "/businessModule/merchant/configure/item/:id?",
                component: () =>
                  import(
                    "@/pages/businessModule/merchant/children/ticket-item.vue"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "/businessModule/waterMeter",
        component: () => import("@/pages/businessModule/water-meter/index.vue"),
        meta: {
          id: 510,
        },
      },
      {
        path:'/businessModule/sanyaWaterMeter',
        component: () => import('@/pages/businessModule/water-meter-sanya/index.vue'),
        meta:{
          id: 556
        }
      },
      { // 装修管理
        path:'/businessModule/examine',
        component:Main,
        meta:{
          id:234
        },
        children: [
          {
            name: "施工图管理",
            path: "/businessModule/examine/construction",
            component: () =>
              import("@/pages/businessModule/examine/construction.vue"),
            meta: {
              id: 238,
            },
          },
          {
            name: "平面图管理",
            path: "/businessModule/examine/plan",
            component: () => import("@/pages/businessModule/examine/plan.vue"),
            meta: {
              id: 237,
            },
          },
          {
            name: "店铺效果图管理",
            path: "/businessModule/examine/shop",
            component: () => import("@/pages/businessModule/examine/shop.vue"),
            meta: {
              id: 236,
            },
          },
          {
            name: "围挡效果图管理",
            path: "/businessModule/examine/enclosure",
            component: () =>
              import("@/pages/businessModule/examine/enclosure.vue"),
            meta: {
              id: 235,
            },
          },
        ],
      },
      {
        // 人事管理
        path: "/businessModule/personnel",
        component: Main,
        meta: {
          id: 241,
        },
        children: [
          {
            name: "入职申请",
            path: "/businessModule/personnel/employmentApplication",
            component: () =>
              import(
                "@/pages/businessModule/personnel/employmentApplication/index.vue"
              ),
            meta: {
              id: 242,
            },
          },
          {
            name: "花名册",
            path: "/businessModule/personnel/roster",
            component: () =>
              import("@/pages/businessModule/personnel/roster/index.vue"),
            meta: {
              id: 244,
            },
          },
          {
            name: "入职流转单",
            path: "/businessModule/personnel/entryFlow",
            component: () =>
              import("@/pages/businessModule/personnel/entryFlow/index.vue"),
            meta: {
              id: 243,
            },
          },
          {
            name: "劳动合同审批",
            path: "/businessModule/personnel/labor-contract",
            component: () =>
              import(
                "@/pages/businessModule/personnel/labor-contract/index.vue"
              ),
            meta: {
              id: 245,
            },
            children: [
              {
                name: "劳动合同审批",
                path: "/businessModule/personnel/labor-contract/detail/:id",
                component: () =>
                  import(
                    "@/pages/businessModule/personnel/labor-contract/detail.vue"
                  ),
                props: true,
              },
              {
                name: "劳动合同审批",
                path: "/businessModule/personnel/labor-contract/edit/:id?",
                component: () =>
                  import(
                    "@/pages/businessModule/personnel/labor-contract/edit.vue"
                  ),
              },
            ],
          },
          {
            name: "转正申请",
            path: "/businessModule/personnel/positive",
            component: () =>
              import("@/pages/businessModule/personnel/positive/index.vue"),
            meta: {
              id: 246,
            },
            children: [
              {
                name: "转正申请",
                path: "/businessModule/personnel/positive/detail/:id",
                component: () =>
                  import(
                    "@/pages/businessModule/personnel/positive/detail.vue"
                  ),
                props: true,
              },
              {
                name: "转正申请",
                path: "/businessModule/personnel/positive/edit/:id?",
                component: () =>
                  import("@/pages/businessModule/personnel/positive/edit.vue"),
              },
            ],
          },
          {
            name: "提醒设置",
            path: "/businessModule/personnel/remind-setting",
            component: () =>
              import(
                "@/pages/businessModule/personnel/remind-setting/index.vue"
              ),
            meta: {
              id: 247,
            },
          },
          {
            name: "合同主体维护",
            path: "/businessModule/personnel/maintenance-contract",
            component: () =>
              import(
                "@/pages/businessModule/personnel/maintenance-contract/index.vue"
              ),
            meta: {
              id: 248,
            },
          },
        ],
      },
      {
        // 信息发布
        path: "/businessModule/notice",
        component: Main,
        meta: {
          id: 473,
        },
        children: [
          // {
          //   name: '信息发布',
          //   path: '/businessModule/notice/setNotice',
          //   component: () => import('@/pages/systemModule/paramConfig/index.vue'),
          //   meta:{
          //     id:473
          //   },
          // },
          {
            name: "通知公告",
            path: "/businessModule/notice/noticeList",
            component: () => import("@/pages/businessModule/notice/index.vue"),
            meta: {
              id: 476,
            },
          },
          {
            name: "营销信息",
            path: "/businessModule/notice/marketInfo",
            component: () =>
              import("@/pages/businessModule/marketInfo/index.vue"),
            meta: {
              id: 530,
            },
          },
        ],
      },
      {
        name: "动态表单",
        path: "/businessModule/dynamicForm/:groupId/:formId",
        component: () => import("@/pages/businessModule/dynamicForm/index.vue"),
        children: [
          {
            name: "动态表单",
            path: "list",
            component: () =>
              import("@/pages/businessModule/dynamicForm/list.vue"),
          },
          {
            name: "动态表单",
            path: "detail/:id",
            component: () =>
              import("@/pages/businessModule/dynamicForm/detail.vue"),
          },
          {
            name: "动态表单",
            path: "noProcessDetail/:id",
            component: () =>
              import("@/pages/businessModule/dynamicForm/noProcessDetail.vue"),
          },
          {
            name: "动态表单",
            path: "edit/:id?/:processId?",
            component: () =>
              import("@/pages/businessModule/dynamicForm/edit.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/shop-mall",
    component: Home,
    meta: {
      id: 535,
      isMainMenu: true,
    },
    children: [
      {
        // 基础数据
        path: "/shop-mall/basic-data",
        component: Main,
        meta: {
          id: 541,
        },
        children: [
          {
            name: "参数配置",
            path: "/shop-mall/basic-data/params-config",
            component: paramsConfig,
            meta: {
              id: 542,
            },
          },
          {
            name: "服务标签",
            path: "/shop-mall/basic-data/service-tag",
            component: serviceTag,
            meta: {
              id: 543,
            },
          },
          {
            name: "快递公司",
            path: "/shop-mall/basic-data/express-company",
            component: expressCompany,
            meta: {
              id: 545,
            },
          },
          {
            name: "快递模版",
            path: "/shop-mall/basic-data/express-template",
            component: expressTemplate,
            meta: {
              id: 544,
            },
          },
          {
            name: "理由配置",
            path: "/shop-mall/basic-data/handle-reason",
            component: handleReason,
            meta: {
              id: 561,
            },
          },
          {
            name: "商品标签",
            path: "/shop-mall/basic-data/goods-tag",
            component: goodsTag,
            meta: {
              id: 568,
            },
          },
          {
            name: '模板编辑',
            path: '/shop-mall/basic-data/express-template/edit/:id?',
            component: expressTemplateEdit
          }
        ],
      }, // 迭代
      {
        path: "/shop-mall/shop-manage",
        component: Main,
        meta: {
          id: 546,
        },
        children: [
          {
            name: "商品类别",
            path: "/shop-mall/shop-manage/shop-category",
            component: shopCategory,
            meta: {
              id: 547,
            },
          },
          {
            name: "商品管理",
            path: "/shop-mall/shop-manage/shop-manage",
            component: shopManage,
            meta: {
              id: 548,
            },
          },
          {
            name: "商品审核",
            path: "/shop-mall/shop-manage/shop-audit",
            component: shopAudit,
            meta: {
              id: 549,
            }
          },
          {
            name: '商品新增',
            path: '/shop-mall/shop-manage/shop-manage/edit/:id?',
            component: shopAdd
          },
          {
            name: '商品新增',
            path: '/shop-mall/shop-manage/shop-manage/detail/:id',
            component: shopDetail
          }
        ]
      }
    ]
  },
  {
    path: "/propertyModule",
    component: Home,
    meta: {
      id: 396,
      isMainMenu: true,
    },
    children: [
      {
        // 物业报修
        path: "/propertyModule/property-repair",
        component: Main,
        meta: {
          id: 397,
        },
        children: [
          {
            name: "工单管理",
            path: "/propertyModule/property-repair/workorder-management",
            component: () =>
              import(
                "@/pages/propertyModule/property-repair/workorder-management/index.vue"
              ),
            meta: {
              id: 398,
            },
            children: [
              {
                name: "工单管理",
                path:
                  "/propertyModule/property-repair/workorder-management/detail/:id?",
                component: () =>
                  import(
                    "@/pages/propertyModule/property-repair/workorder-management/detail.vue"
                  ),
              },
            ],
          },
        ],
      },
      {
        // 报修配置
        path: "/propertyModule/repair-configuration",
        component: Main,
        meta: {
          id: 399,
        },
        children: [
          {
            name: "报修项管理",
            path: "/propertyModule/repair-configuration/repairitem-management",
            component: () =>
              import(
                "@/pages/propertyModule/repair-configuration/repairitem-management/index.vue"
              ),
            meta: {
              id: 400,
            },
          },
          {
            name: "区域设置",
            path: "/propertyModule/repair-configuration/regional-setting",
            component: () =>
              import(
                "@/pages/propertyModule/repair-configuration/regional-setting/index.vue"
              ),
            meta: {
              id: 401,
            },
          },
          {
            name: "报修参数",
            path: "/propertyModule/repair-configuration/repair-parameters",
            component: () =>
              import(
                "@/pages/propertyModule/repair-configuration/repair-parameters/index.vue"
              ),
            meta: {
              id: 402,
            },
          },
        ],
      },
      {
        // 人员管理
        path: "/propertyModule/personnel",
        component: Main,
        meta: {
          id: 403,
        },
        children: [
          {
            name: "维修人员",
            path: "/propertyModule/personnel-management/maintenance-staff",
            component: () =>
              import(
                "@/pages/propertyModule/personnel-management/maintenance-staff/index.vue"
              ),
            meta: {
              id: 404,
            },
          },
        ],
      },
      {
        // 数据报表
        path: "/propertyModule/data-report",
        component: Main,
        meta: {
          id: 405,
        },
        children: [
          {
            name: "工单统计",
            path: "/propertyModule/data-report/workorder-statistics",
            component: () =>
              import(
                "@/pages/propertyModule/data-report/workorder-statistics/index.vue"
              ),
            meta: {
              id: 406,
            },
            children: [
              {
                name: "工单管理",
                path:
                  "/propertyModule/data-report/workorder-statistics/detail/:id",
                component: () =>
                  import(
                    "@/pages/propertyModule/data-report/workorder-statistics/detail.vue"
                  ),
              },
            ],
          },
          {
            name: "人员统计",
            path: "/propertyModule/data-report/personnel-statistics",
            component: () =>
              import(
                "@/pages/propertyModule/data-report/personnel-statistics/index.vue"
              ),
            meta: {
              id: 407,
            },
          },
          {
            name: "报修项统计",
            path: "/propertyModule/data-report/repairitem-statistics",
            component: () =>
              import(
                "@/pages/propertyModule/data-report/repairitem-statistics/index.vue"
              ),
            meta: {
              id: 408,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/tourModule" /*巡场管理*/,
    component: Home,
    meta: {
      id: 441,
    },
    children: [
      {
        path: "/tourModule/config",
        component: Main,
        meta: {
          id: 442,
        },
        children: [
          {
            path: "/tourModule/config/areaConfig",
            component: tourAreaConfig,
            meta: {
              id: 444,
            },
          },
          {
            path: "/tourModule/config/typeConfig",
            component: tourTypeConfig,
            meta: {
              id: 443,
            },
          },
          {
            path: "/tourModule/config/parameterConfig",
            component: tourParameterConfig,
            meta: {
              id: 445,
            },
          },
        ],
      },
      {
        path: "/tourModule/report",
        component: Main,
        meta: {
          id: 446,
        },
        children: [
          {
            path: "/tourModule/report/dashboard",
            component: tourDashboard,
            meta: {
              id: 452,
            },
          },
          {
            path: "/tourModule/report/department",
            component: departmentStatistic,
            meta: {
              id: 448,
            },
          },
          {
            path: "/tourModule/report/type",
            component: typeStatistic,
            meta: {
              id: 449,
            },
          },
          {
            path: "/tourModule/report/workorder",
            component: workorderStatistic,
            meta: {
              id: 447,
            },
            children: [
              {
                name: "工单详情",
                path: "/tourModule/report/workorder/detail",
                component: tourDetail,
              },
            ],
          },
        ],
      },
      {
        path: "/tourModule/management",
        component: Main,
        meta: {
          id: 450,
        },
        children: [
          {
            path: "/tourModule/management/workorder/my",
            component: tourMain,
            meta: {
              id: 451,
            },
            children: [
              {
                name: "我的工单",
                path: "/tourModule/management/workorder/myList",
                component: tourMyList,
                meta: {
                  isShow: true,
                },
              },
              {
                name: "新增",
                path: "/tourModule/management/workorder/edit",
                component: tourEdit,
                meta: {
                  isShow: true,
                },
              },
              {
                name: "详情",
                path: "/tourModule/management/workorder/my/detail",
                component: tourDetail,
                meta: {
                  isShow: true,
                },
              },
            ],
          },
          {
            path: "/tourModule/management/workorder/total",
            component: tourMain,
            meta: {
              id: 462,
            },
            children: [
              {
                name: "工单管理",
                path: "/tourModule/management/workorder/totalList",
                component: tourTotalList,
                meta: {
                  isShow: true,
                },
              },
              {
                name: "详情",
                path: "/tourModule/management/workorder/total/detail",
                component: tourDetail,
                meta: {
                  isShow: true,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    // 设备管理
    path: "/systemModule/device-manage",
    component: Home,
    meta: {
      id: 569,
      isMainMenu: true,
    },
    children: [
      {
        name: "打印机",
        path: "/systemModule/device-manage/printer",
        component: () =>
          import("@/pages/systemModule/device/printer/index.vue"),
        meta: {
          id: 570,
        },
      },
    ]
  },
];
const routes = [
  {
    path: '/',
    redirect: '/login',
    component: Login
  },
  {
    path: "/callback",
    component: Callback,
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: "/form",
    component: () => import("@/components/dynamicForm/index.vue"),
  },
  {
    name: "扫码核验",
    path: "/businessModule/dynamicForm/verificate/:code/:token",
    component: () =>
      import("@/pages/businessModule/dynamicForm/verificate.vue"),
  },
  {
    name: "装修申请打印",
    path: "/businessModule/examine/decoration-print",
    component: () =>
      import("@/pages/businessModule/examine/decoration-print.vue"),
  },
  {
    name: "入职申请打印",
    path: "/businessModule/personnel/print",
    component: () =>
      import(
        "@/pages/businessModule/personnel/employmentApplication/print.vue"
      ),
  },
  {
    name: "入职流转打印",
    path: "/businessModule/personnel/entryFlow/print",
    component: () =>
      import("@/pages/businessModule/personnel/entryFlow/print.vue"),
  },
  {
    name: "劳动合同审批打印",
    path: "/businessModule/personnel/labor-contract/print",
    component: () =>
      import("@/pages/businessModule/personnel/labor-contract/print.vue"),
  },
  {
    name: "转正申请打印",
    path: "/businessModule/personnel/positive/print",
    component: () =>
      import("@/pages/businessModule/personnel/positive/print.vue"),
  },
  {
    name: "动态表单打印",
    path: "/businessModule/dynamicForm/print",
    component: () => import("@/pages/businessModule/dynamicForm/print.vue"),
  },
];

const createRouter = () =>
  new VueRouter({
    base: process.env.BASE_URL,
    routes,
  });

const router = createRouter();
// 重置路由
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

let rematch = false;
router.beforeEach((to, from, next) => {
  const sysModule = JSON.parse(localStorage.getItem("sysModule"));
  if (to.matched.length === 0) {
    //如果未匹配到路由
    if (rematch) {
      return from.path ? next({ path: from.path }) : next("/"); //如果上级也未匹配到路由则跳转主页面，如果上级能匹配到则转上级路由
    }
    rematch = true;
    initRoute(sysModule);
    next({ ...to });
  } else {
    if (
      to.path === "/login" ||
      to.path == "/callback" ||
      to.path.includes("verificate")
    ) {
      next();
    } else {
      const userInfo = localStorage.getItem("userInfo");
      const token = JSON.parse(userInfo) && JSON.parse(userInfo).token;
      const cookie = document.cookie.includes("DK_TOKEN");
      if (token && cookie) {
        next();
      } else {
        if (userInfo && userInfo.type === 1) {
          next("/login");
        } else {
          window.top.postMessage("reloadPortal", "*");
        }
      }
    }
  }
});

export default router;
