<!-- 查询
<d-search :searchData="searchData"/>

searchData:{
  type:Object,
  param:   searchItem:[{
              label:'选择店铺',
              value:'shopId',
              type:'linkageSelect',
              placeholder:'',
              options:[],
              optionProps:{
              value: 'name',
              label: 'name',
              children: 'childList'
            }
          },{
              label:'提交日期',
              value:'commitTime',
              type:'scopeDate',
              startPlaceholder:"开始日期",
              endPlaceholder:"结束日期" 
          }],

  searchBtn:{
          queryBtn:true,  // 打开按钮 
          exportBtn:true,
          isOpen:{  
            type:true, // 是否有展开关闭
            open:true  // 展开关闭开关
          }
  },
}
 type:  linkageSelect  为 多级选择框
        commonSelect 为普通选择框
        scopeDate 为 范围时间选择器
        commonDate 为 普通时间选择器
        commonInput 为普通input
-->

<template>
  <div class="search searchBox clearfix">
    <template v-for="item in searchData.searchItem">
      <div
        class="searchItem searchForm"
        :key="item.lable"
        v-if="item.type === 'commonSelect'"
      >
        <span class="title">{{ item.label }}:</span>
        <el-select
          v-model="searchData.params[item.value]"
          placeholder="请选择"
          :multiple="item.multiple"
          :collapse-tags="item.multiple"
          :filterable="item.filterable"
          size="medium"
          :clearable="item.clearable != undefined ? item.clearable : true"
        >
          <el-option
            v-for="(option, index) in item.options"
            :key="index"
            :label="option.label"
            :value="option.value"
          ></el-option>
        </el-select>
      </div>
      <div
        class="searchItem"
        :key="item.lable"
        v-if="item.type === 'linkageSelect'"
      >
        <span class="title">{{ item.label }}:</span>
        <el-cascader
          :options="item.options"
          v-model="searchData.params[item.value]"
          :props="item.optionProps"
          ref="cascader"
          :filterable="true"
          size="medium"
          clearable
        ></el-cascader>
      </div>
      <div
        class="searchItem"
        :key="item.lable"
        v-if="item.type === 'commonInput'"
      >
        <span class="title">{{ item.label }}:</span>
        <el-input
          v-model="searchData.params[item.value]"
          :placeholder="item.placeholder || '请输入'"
          size="medium"
        ></el-input>
      </div>
      <div
        class="searchItem"
        :key="item.lable"
        v-if="item.type === 'scopeDate'"
      >
        <span class="title">{{ item.label }}:</span>
        <el-date-picker
          v-model="searchData.params[item.value]"
          type="daterange"
          range-separator="至"
          :clearable="!item.noClear"
          :start-placeholder="item.startPlaceholder"
          :end-placeholder="item.endPlaceholder"
          :picker-options="item.options || null"
          value-format="yyyy-MM-dd"
          size="medium"
        ></el-date-picker>
      </div>

      <div
        class="searchItem"
        :key="item.label"
        v-if="item.type === 'commonDatetime'"
      >
        <span class="title">{{ item.label }}:</span>
        <el-date-picker
          v-model="searchData.params[item.value]"
          type="datetimerange"
          rang-separator="至"
          :start-placeholder="item.startPlaceholder"
          :end-placeholder="item.endPlaceholder"
          :default-time="item.defaultTime || null"
          value-format="yyyy-MM-dd HH:mm:ss"
          :clearable="item.clearable != undefined ? item.clearable : true"
          :picker-options="item.options || null"
          size="medium"
        ></el-date-picker>
      </div>
      <div
        class="searchItem"
        :key="item.lable"
        v-if="item.type === 'commonDate'"
      >
        <span class="title">{{ item.label }}:</span>
        <el-date-picker
          v-model="searchData.params[item.value]"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          size="medium"
        ></el-date-picker>
      </div>
      <div
        class="searchItem"
        :key="item.lable"
        v-if="item.type === 'datetime'"
      >
        <span class="title">{{ item.label }}:</span>
        <el-date-picker
          v-model="searchData.params[item.value]"
          type="datetime"
          placeholder="选择时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          size="medium"
        ></el-date-picker>
      </div>
    </template>
    <slot></slot>
    <div class="button searchItem" v-if="searchData.searchBtn">
      <el-button
        type="primary"
        size="medium"
        v-if="searchData.searchBtn.queryBtn"
        @click="searchEvent"
      >
        查询
      </el-button>
      <el-button
        size="medium"
        v-if="searchData.searchBtn.resetBtn"
        @click="resetEvent"
      >
        重置
      </el-button>
      <el-button
        size="medium"
        type="primary"
        v-if="searchData.searchBtn.exportBtn"
        @click="exportEvent"
      >
        导出
      </el-button>
      <el-button
        size="medium"
        v-if="searchData.searchBtn.viewBtn"
        @click="viewEvent"
      >
        查看
      </el-button>
      <template
        v-if="searchData.searchBtn.isOpen && searchData.searchBtn.isOpen.type"
      >
        <span
          class="open"
          v-if="searchData.searchBtn.isOpen.open"
          @click="openEvent(false)"
        >
          展开
          <i class="el-icon-arrow-down"></i>
        </span>
        <span class="open" v-else @click="openEvent(true)">
          收起
          <i class="el-icon-arrow-up"></i>
        </span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'd-search',
  data() {
    return {}
  },
  props: {
    searchData: {
      type: Object,
      required: true,
    },
  },
  computed: {},
  components: {},
  created() {},
  mounted() {},
  methods: {
    searchEvent() {
      //点击查询按钮
      this.$emit('searchEvent')
    },
    resetEvent() {
      // 点击重置按钮
      this.searchData.params.nameId && (this.searchData.params.nameId = '')
      this.searchData.params.mobile && (this.searchData.params.mobile = '')
      for (let itr of this.searchData.searchItem) {
        this.$set(
          this.searchData.params,
          itr.value,
          Array.isArray(this.searchData.params[itr.value]) ? [] : '',
        )
      }
      this.$emit('resetEvent')
    },
    exportEvent() {
      // 点击导出按钮
      this.$emit('exportEvent')
    },
    openEvent(status) {
      // 点击展开
      this.$emit('openEvent', status)
    },
    viewEvent() {
      this.$emit('viewEvent')
    },
  },
  watch: {},
}
</script>
<style lang="scss" scoped>
.search {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  .searchItem {
    display: flex;
    align-items: center;
    float: left;
  }
  .searchItem > * {
    float: left;
  }
  .title {
    font-size: 14px;
    margin-right: 5px;
    line-height: 30px;
    min-width: 100px;
  }
  .searchItem:last-child {
    margin-right: 0px;
  }
  .button {
    padding-left: 16px;
    float: left;
  }
  .open {
    color: #1890ff;
    margin: 0 20px;
    cursor: pointer;
  }
}
</style>
