import { request } from './fetch';

// 企业商城
export const insertManagerApi = config => request('post', '/business/company/insert/manager', config) // 新增企业管理员
export const insertEmployeeApi = config => request('post', '/business/company/insert/employee', config) // 新增企业员工
export const exportCompanyManagerApi = config => request('get', '/business/company/export/company/manager', config, 'blob') // 导出企业管理员模板
export const importCompanyManagerApi = config => request('post', '/business/company/import/company/manager', config) // 导入企业管理员
export const exportCompanyEmployeeApi = config => request('get', '/business/company/export/company/employee', config, 'blob') // 导出企业员工模板
export const importCompanyEmployeeApi = config => request('post', '/business/company/import/company/employee', config) // 导入企业员工
export const updateUserApi = config => request('put', '/business/company/update/user', config) // 修改企业人员
export const getCompanyApi = config => request('get', '/business/company/get', config) // 企业人员详情
export const getCompanyListApi = config => request('get', '/business/company/list', config) // 企业人员列表


export const onlineSubmitApi = config => request('put', '/business/shop/online/submit', config) // 店铺开店提审
export const shopOnlineListApi = config => request('get', '/business/shop/online/list', config) // 店铺开店列表
export const shopOnlineApi = config => request('get', '/business/shop/online', config) // 店铺开店详情
export const onlineRefuseBatchApi = config => request('put', '/business/shop/online/refuse/batch', config) // 店铺开店批量驳回
export const shopOnlineUpdateApi = config => request('put', '/business/shop/online/update', config) // 店铺开店修改或重新认证
export const onlinePassBatch = config => request('put', '/business/shop/online/pass/batch', config) // 店铺开店批量通过
export const onlineOpenBatch = config => request('put', '/business/shop/online/open', config) // 店铺开店开启
export const onlinePauseBatch = config => request('put', '/business/shop/online/pause', config) // 店铺开店暂停
export const onlinePauseRemove = config => request('put', '/business/shop/online/remove', config) // 店铺开店删除


export const merchantRoleApi = config => request('get', '/business/merchant/role', config) // 获取商户可授权角色
export const merchantUpdateRoleApi = config => request('put', '/business/merchant/update/role', config) //角色授权(覆盖更新)

export const businessListFirstApi = config => request('get', '/sys/business/list/first', config) // MDM一级业态
export const businessListSecondApi = config => request('get', '/sys/business/list/second', config) // MDM二级业态



export const goodsOrderListApi = config => request('get', '/business/goods/order/list', config) // 销售订单列表
export const goodsOrderExportApi = config => request('get', '/business/goods/order/export', config, 'blob') // 导出订单列表
export const goodsOrderApi = config => request('get', '/business/goods/order', config) // 订单详情
export const goodsOrderReceiveApi = config => request('put', '/business/goods/order/receive', config) // 修改收货信息
export const goodsOrderSendSkuListApi = config => request('get', '/business/goods/order/send/sku/list', config) // 可发货订单商品列表
export const goodsOrderSendApi = config => request('post', '/business/goods/order/send', config) // 分包发货
export const goodsOrderSendListApi = config => request('get', '/business/goods/order/send/list', config) // 发货订单列表
export const goodsOrderDeleteApi = config => request('delete', '/business/goods/order/delete/send/order', config, 'json') // 删除发货订单
export const goodsOrderSendConfirmApi = config => request('put', '/business/goods/order/send/confirm', config) // 确认发货
export const goodsOrderPickUpConfirmApi = config => request('put', '/business/goods/order/pick/up/confirm', config) // 确认自提
export const goodsOrderReturnRefuseApi = config => request('put', '/business/goods/order/return/refuse', config) // 驳回退货申请
export const goodsOrderReturnPassApi = config => request('put', '/business/goods/order/return/pass', config) // 通过退货申请
export const confirmRefuseApi = config => request('put', '/business/goods/order/return/confirm/refuse', config) // 拒绝退货
export const confirmPassApi = config => request('put', '/business/goods/order/return/confirm/pass', config) // 确认退货
export const goodsOrderReturnListApi = config => request('get', '/business/goods/order/return/list', config) // 退货订单列表
export const goodsOrderReturnExportApi = config => request('get', '/business/goods/order/return/export', config, 'blob') // 导出退货订单列表
export const goodsOrderSkuSendApi = config => request('get', '/business/goods/order/logistics/info', config) // 查询订单下的商品物流信息
export const goodsOrderPayDetailApi = config => request('get', '/business/goods/order/pay/detail', config) // 查询支付-退款信息（查询成功后会完成处理中的状态）
export const goodsOrderCountApi = config => request('get', '/business/goods/order/count', config) // 查询店铺订单数量
export const goodsOrderByOrdernoApi = config => request('get', '/business/goods/order/by/order/no', config) // 根据订单号查询订单详情

export const logisticsCompanyListApi = config => request('get', '/business/logistics/company/list', config) // 快递公司列表

export const goodsOrderPrintApi = config => request('get', '/business/goods/order/print', config) // 打印订单
export const deviceListApi = config => request('get', '/business/device/list', config) // 设备列表
