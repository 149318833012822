export default {
    shopCache:{},
    checkcache:{
        realname:{},
        reportSale:{},
        outAllow:{},
        paperShandle:{}
    },
    content_title:'',
    DECORATION:{
        CONSTRUCTION_TYPEID:1,
        SHOP_EFFECT_TYPEID:2,
        FLAT_TYPEID:3,
        FENCE_TYPEID:4
    },
    PERSONNEL:{
        ENTRY_TYPEID:5,
        ENTRY_FLOW_TYPEID:6,
        LABOR_CONTRACT_TYPEID:7,
        POSITIVE_TYPEID:8
    }


}
