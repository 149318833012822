/* 
  用于生成饿了么表单的校验规则的工具库
*/

export const formValidator = {
  /**
   * 必填校验
   * @param {*} message
   * @param {*} trigger
   * @returns
   */
  empty(name = "值", trigger = "blur", type = "string") {
    return {
      required: true,
      trigger,
      message: (trigger === "blur" ? "请输入" : "请选择") + name,
      type,
    };
  },

  /**
   * 长度校验
   * @param {string, number, string|number[]} len 长度限制
   * 若传string或number，则表示一个确定的长度
   * 若传array，则表示一个范围
   * array若只有一个参数，则表示从1到该值
   * 若有两个参数，则表示从左值到右值
   * @param {string} name
   * @param {string} trigger
   * @returns
   */
  length(len = [20], name = "值", trigger = "blur") {
    let validator = null;
    if (len.constructor == Array) {
      if (len.length == 1) {
        if (len[0] < 2) {
          throw new Error("传参为一个项的数组时，参数不可小于等于1");
        }
        len = [1, len[0]];
      }
      validator = (rule, value, cb) => {
        if (value.length < len[0] || value.length > len[1]) {
          cb(new Error(`${name}应在${len[0]}到${len[1]}个字符范围内`));
        } else {
          cb();
        }
      };
    } else {
      len = +len;
      if (len != len) {
        throw new Error("参数类型不合法");
      }
      validator = (rule, value, cb) => {
        if (value.length != len) {
          cb(new Error(`${name}应为${len}个字符`));
        } else {
          cb();
        }
      };
    }
    return { validator, trigger };
  },
};

export const validatePhone = (rule, value, callback) => {
  let reg = /^1(3|4|5|6|7|8|9)[0-9]{9}$/;
  if (!reg.test(value)) {
    return callback(new Error("请输入正确的手机号"));
  }
  callback();
};

export const validateIDcard = (rule, value, callback) => {
  let reg1 = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/; // 18位
  let reg2 = /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$/; // 15位
  if (!(reg1.test(value) || reg2.test(value))) {
    return callback(new Error("请输入正确的身份证号"));
  }
  callback();
};

export const validateEmail = (rule, value, callback) => {
  // let reg = /^\w+@[a-z0-9]+\.[a-z]{2,4}$/;
  let reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
  if (!value) {
  } else if (value && !reg.test(value)) {
    return callback(new Error("请输入正确的邮箱"));
  }
  callback();
};

export const validateUserPwd = (rule, value, callback) => {
  let reg = /(^[0-9]{8,32}$)|(^[a-zA-Z]{8,32}$)|(^[a-z0-9A-Z]{8,32}$)|(^[~!.`@#$%^&*/?|:'-=_+\[\]\{\}\\\(\)a-zA-Z0-9]{8,32}$)/;
  if (!value) {
  } else if (value && !reg.test(value)) {
    return callback(
      new Error(
        "请输入8-32位密码，包含大小写字母，数字，特殊字符中的一种或多种"
      )
    );
  }
  callback();
};

export const floatNum = (len, tag) => {
  return (rule, value, callback) => {
    let str = "0|";
    if (tag == "equal") {
      str = `(^[1-9]{1}[0-9]{0,${8 -
        len -
        2}}\.[0-9]{${len}}$)|(^[1-9]{1}[0-9]{1,7}$)|(^0\.[0-9]{${len}}$)`;
    } else {
      for (let i = 1; i <= len; i++) {
        str += `(^[1-9]{1}[0-9]{0,${8 - i - 2}}\.[0-9]{${i}}$)|`;
      }
      str += `(^[1-9]{1}[0-9]{0,7}$)|(^0\.[0-9]{${len}}$)`;
    }
    let reg = new RegExp(str);
    if (value && !reg.test(value)) {
      return callback(`最长8位，支持小数点后${len}位`);
    }
    callback();
  };
};

export const validateSpeCharacter = (rule, value, callback) => {
  let reg = /^[0-9a-zA-Z\u4e00-\u9fa5]+$/;
  if (value && !reg.test(value)) {
    return callback(new Error("不允许包含特殊字符"));
  }
  callback();
};

export const validInt = (rule, value, callback) => {
  let reg = /^[1-9]\d*$/;
  if (value && !reg.test(value)) {
    return callback(new Error("请输入正整数"));
  }
  callback();
};
