<template>
 <router-view></router-view>
</template>
<script>
export default {
  data(){
    return {
    }
  },
  watch: {
  }
}
</script>

<style lang="scss">
.el-loading-spinner i{
  color:#fff !important;
  font-size: 32px !important;
}
.el-loading-spinner .el-loading-text{
  color:#fff !important;
}

.searchForm .el-select--medium .el-input--medium{
  width: 220px !important;
}
.el-pagination{
  text-align: center !important;
  padding: 10px 5px  !important;
}
.el-table{
  flex: 1 !important;
  overflow-y: auto !important;
  overflow-x: hidden ;
}
.el-table th{
  background: #eef1f6 !important;
  color:#606266 !important;
}
.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
  background: #d9e9fb !important;
}
.el-table--border::after, .el-table--group::after, .el-table::before{
  display: none;
}
.el-upload-list__item {
  transition: none !important;
}
.el-dialog__body{
  max-height: 580px;
  overflow: auto;
}
</style>